import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "src/app/constants";
import { RealCases } from "src/app/services/api";
import { RootState } from "..";
import { ManyRealCases } from "./state";

export const selectFeature = (state: RootState): ManyRealCases => state.realCases.manyRealCases;

export const selectID = (state: RootState): any => state.realCases.id;

export const selectAll = createSelector(selectFeature, (manyRealCases: ManyRealCases) => Object.values(manyRealCases).filter(Boolean));

export const selectPaged = createSelector(selectAll, (realCases: RealCases[], page: number) =>
  realCases
    .sort((a: RealCases, b: RealCases) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyRealCases: RealCases[], id: string) => manyRealCases.find(realCases => realCases.id === id));

export const selectLoading = (state: RootState): boolean => state.realCases.loading;

export const selectTotal = (state: RootState): number => state.realCases.total;

export const selectCurrentRealCase = (state: RootState): any => state.realCases.currentRealCase;

export const selectRelatedRealCases = (state: RootState): ManyRealCases => state.realCases.manyRealCases;

export const selectRelatedRealCasesArray = (state: RootState): RealCases[] => state.realCases.manyRealCasesArray;

export const selectArrayFilteredRealCases = (state: RootState): RealCases[] => state.realCases.manyRealCasesArray;

export const selectFilteredRealCases = (categories: string[]):
MemoizedSelector<RootState, RealCases[], DefaultProjectorFn<RealCases[]>> =>
  createSelector(selectRelatedRealCases, (news: ManyRealCases) =>{
    let val : RealCases[] = Object.values(news); 
    let realCasesFiltered : RealCases[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          realCasesFiltered.push(element);
        }
      });
    });   
    return realCasesFiltered;
  }  
);

export const selectFilteredRealCasesArray = (categories: string[]):
MemoizedSelector<RootState, RealCases[], DefaultProjectorFn<RealCases[]>> =>
  createSelector(selectRelatedRealCasesArray, (realCases: RealCases[]) =>{
    let realCasesFiltered : RealCases[] = [];        
    realCases.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          realCasesFiltered.push(element);
        }
      });
    }); 
    return realCasesFiltered;
  }  
);

export const selectFilteredSearchRealCasesArray = (text: string): 
  MemoizedSelector<RootState, RealCases[], DefaultProjectorFn<RealCases[]>> =>
  createSelector(selectRelatedRealCasesArray, (realCases: RealCases[]) =>{
    let realCasesFiltered : RealCases[] = [];        
    realCases.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        realCasesFiltered.push(element);
      }
    });   
    return realCasesFiltered;
  }   
  );