/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScienceInsights {
    category: any; 
    description: string;
    id: string;    
    imagePreviewLink: string;
    duration: string;
    link: string;
    title: string;
    createdAt: string;
    type: string;
    userId: string;
    likes: number;
    dislikes: number;
    views: number;
}

