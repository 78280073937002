export * from './auth.service';
import { AuthService } from './auth.service';
export * from './auth.serviceInterface'
export * from './news.service';
export * from './ecg.service';
export * from './cardio3D.service';
import { NewsService } from './news.service';
import { EcgService } from './ecg.service';
import { Cardio3DService } from './cardio3D.service';
export * from './news.serviceInterface';
export * from './ecg.serviceInterface';
export * from './cardio3D.serviceInterface';
export * from './interactiveImages.service';
import { InteractiveImagesService } from './interactiveImages.service';
export * from './scienceInsights.service';
import { ScienceInsightsService } from './scienceInsights.service';
export * from './development.service';
import { DevelopmentService } from './development.service';
export * from './realCases.service';
import { RealCasesService } from './realCases.service';
export * from './news.serviceInterface'
export * from './cartoCases.service';
import { CartoCasesService } from './cartoCases.service';
export * from './dicom.service';
import { DicomService } from './dicom.service';
export const APIS = [
    AuthService, NewsService, InteractiveImagesService, DevelopmentService, ScienceInsightsService, 
    RealCasesService, Cardio3DService, EcgService, CartoCasesService, DicomService];
