import { RootState } from "..";

export const selectEmail = (state: RootState): string => state.user.email;

export const selectUserName = (state:RootState): string => state.user.name;

export const selectUserSurName = (state:RootState): string => state.user.surname;

export const selectUserOrganization = (state:RootState): string => state.user.organisation;

export const selectUserRole = (state:RootState): string => state.user.webRole;

export const selectUserOrganisationRole = (state:RootState): string => state.user.organisationRole;

export const selectUserMyNews = (state:RootState): Array<string> => state.user.myNews;

export const selectUserMyRealCases = (state:RootState): Array<string> => state.user.myRealCases;

export const selectUserMyDevelopment = (state:RootState): Array<string> => state.user.myDevelopment;

export const selectUserMyCardio3d = (state:RootState): Array<string> => state.user.myCardio3d;

export const selectUserMyInteractiveImage = (state:RootState): Array<string> => state.user.myInteractiveImage;

export const selectUserMyEcg = (state:RootState): Array<string> => state.user.myEcg;

export const selectUserMyPatient = (state:RootState): Array<string> => state.user.myPatient;

export const selectUserMySession = (state:RootState): Array<string> => state.user.mySession;

export const selectUserConnectedDevices = (state:RootState): Array<string> => state.user.connectedDevices;

export const selectUserMyActiveSession = (state:RootState): Array<string> => state.user.myActiveSession;

export const selectUserMyCartoCases = (state:RootState): Array<string> => state.user.myCartoCases;

export const selectUserMyDicom = (state:RootState): Array<string> => state.user.myDicom;

export const selectUserMyLikes = (state:RootState): Array<string> => state.user.likes;

export const selectUserMyDislikes = (state:RootState): Array<string> => state.user.dislikes;

export const selectUserPassword = (state:RootState): string => state.user.password;

export const selectUserImage = (state:RootState): string => state.user.image;

export const selectUserEmail = (state:RootState): string => state.user.email;

export const selectUserID = (state:RootState): string => state.user.uid;

export const selectLoading = (state:RootState): boolean => state.user.loading;

export const selectUserData = (state:RootState): any => state.user;

export const selectUserPhone = (state:RootState): any => state.user.phone;

export const selectUserSmsVerification = (state:RootState): any => state.user.smsVerification;

