import { News } from "src/app/services/api";

export const persistedKeys = ["total","manyNews", "currentNew"];

export interface ManyNews {
  [id: string]: News;
}

export interface NewsState {
  currentNew: any;
  manyNews: ManyNews;
  manyNewsArray: News[];
  loading: boolean;
  total: number;
  category : string,
  content : string,
  image : string,
  title : string,
  type : string,
  id : string,
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: NewsState = {
  currentNew: {},
  manyNews: {},
  manyNewsArray: [],
  loading: false,
  total: 0,
  category : "",
  content : "",
  image : "",
  title : "",
  type : "",
  id: "",
  likes: 0,
  dislikes: 0,
  views: 0
};
