import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as ScienceInsightsCategoryActions from "./actions";
import { ScienceInsightsCategory } from "src/app/services/api/model/scienceInsightsCategory";
import { ScienceInsightsCategoryService } from "src/app/services/api/api/scienceInsightsCategory.service";

@Injectable()
export class ScienceInsightsCategoryEffects {
  public loadManyScienceInsightsCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScienceInsightsCategoryActions.loadManyScienceInsightsCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.scienceInsightsCategoryService.scienceInsightsCategoryControllerGetManyScienceInsightsCategories({ page, limit}); 
          return ScienceInsightsCategoryActions.loadManyScienceInsightsCategoriesSuccess({ manyScienceInsightsCategories: result });
        } catch (error) {
          console.error(error)
          return ScienceInsightsCategoryActions.loadManyScienceInsightsCategoriesFailure();
        }
      }),
    ),
  );
  constructor(private actions$: Actions, public scienceInsightsCategoryService: ScienceInsightsCategoryService) {}
}
