import { createAction, props } from "@ngrx/store";
import { EcgCategoryControllerGetManyEcgCategoriesRequestParams } from "src/app/services/api/api/ecgCategory.serviceInterface";
import { EcgCategory } from "src/app/services/api/model/ecgCategory";

export const loadManyEcgCategories = createAction("[ECG Category] Load Many ECG categories", props<EcgCategoryControllerGetManyEcgCategoriesRequestParams>());
export const loadManyEcgCategoriesSuccess = createAction("[ECG Category] Load Many ECG categories Success", props<{ manyEcgCategories: Array<EcgCategory> }>());
export const loadManyEcgCategoriesFailure = createAction("[ECG Category] Load Many ECG categories Failure");

export const loadEcgCategory = createAction("[ECG Category] Load ECG Category", props<{ ecgCategoryId: string }>());
export const loadEcgCategorySuccess = createAction("[ECG Category] Load RCG Category Success", props<{ ecgCategory: EcgCategory }>());
export const loadEcgCategoryFailure = createAction("[ECG Category] Load ECG Category Failure");
