import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as ScienceInsightsCategoryActions from "./actions";
import { ScienceInsightsCategory } from "src/app/services/api/model/scienceInsightsCategory";

export const scienceInsightsCategoryReducer = createReducer(
  initialState,

  on(ScienceInsightsCategoryActions.loadManyScienceInsightsCategories, 
    ScienceInsightsCategoryActions.loadScienceInsightsCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(ScienceInsightsCategoryActions.loadManyScienceInsightsCategoriesFailure, 
    ScienceInsightsCategoryActions.loadScienceInsightsCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(ScienceInsightsCategoryActions.loadManyScienceInsightsCategoriesSuccess, (state, { manyScienceInsightsCategories }) => {
    const newScienceInsightsCategories: { [key: string]: ScienceInsightsCategory } = {};
    manyScienceInsightsCategories.forEach((scienceInsightsCategory: ScienceInsightsCategory) => {
      newScienceInsightsCategories[scienceInsightsCategory.title!] = scienceInsightsCategory;
    });
    return {
      ...state,
      manyScienceInsightsCategories: {
        ...newScienceInsightsCategories,
      },
      totalManyScienceInsightsCategories: manyScienceInsightsCategories.length,
      loading: false,
    };
  }),

  on(ScienceInsightsCategoryActions.loadScienceInsightsCategorySuccess, (state, { scienceInsightsCategory }) => {
    return {
      ...state,
      manyScienceInsightsCategories: {
        ...state.manyScienceInsightsCategories,
        [scienceInsightsCategory.title!]: scienceInsightsCategory,
      },
      loading: false,
    };
  })
);
