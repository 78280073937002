import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as NewsActions from "./actions";
import { NewsService } from "src/app/services/api";
import * as FromNews from "../news/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "..";

@Injectable()
export class NewsEffects {
  public loadManyNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(NewsActions.loadManyNews),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : any = await this.newsService.newsControllerGetManyNews({ page, limit, filters}); 
          return NewsActions.loadManyNewsSuccess({ manyNews: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return NewsActions.loadManyNewsFailure();
        }
      }),
    ),
  );

  public updateNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.updateNews),
      withLatestFrom(this.store.select(FromNews.selectID)),
      mergeMap(async (action) => {
        const news = action[0].news; 
        try {
          const result: any = await this.newsService.newsControllerUpdateOne({
            news : news,
          });
          return NewsActions.updateNewsSuccess({ news: result });
        } catch (error: any) {
          console.error(error);
          return NewsActions.updateNewsFailure({ reason: error.message });
        }
      })
    )
  );

  public createNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.createNews),
      withLatestFrom(this.store.select(FromNews.selectID)),
      mergeMap(async (action) => {
        const news = action[0].news; 
        try {
          const result: any = await this.newsService.newsControllerCreateNews({
            news : news,
          });
          return NewsActions.createNewsSuccess({ news: result });
        } catch (error: any) {
          console.error(error);
          return NewsActions.createNewsFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.deleteNews),
      withLatestFrom(this.store.select(FromNews.selectID)),
      mergeMap(async (action) => {
        const news = action[0].news; 
        try {
          const result: any = await this.newsService.newsControllerDeleteNews({
            news : news,
          });
          return NewsActions.deleteNewsSuccess({ newsId: result.id });
        } catch (error: any) {
          console.error(error);
          return NewsActions.deleteNewsFailure({ reason: error.message });
        }
      })
    ),
  );

  public getNews$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.getNews),
      withLatestFrom(this.store.select(FromNews.selectID)),
      mergeMap(async (action) => {
        const news = action[0].news; 
        try {
          const result: any = await this.newsService.newsControllerGetNews({
            news : news,
          });
          return NewsActions.getNewsSuccess({ news: result });
        } catch (error: any) {
          console.error(error);
          return NewsActions.getNewsFailure({ reason: error.message });
        }
      })
    ),
  );
  
  constructor(private actions$: Actions, private newsService: NewsService,private store: Store<RootState>) {}
}
