/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LoginResponse { 
    company: number;
    companyRole: string;
    createdAt: string;
    email: string;
    hashID: string;
    name: string;
    netRole: string;
    surname: string;
    password: string;
    userID: string;
    companyId: string; 
    userImage: string; 
    facebookLink: string; 
    twitterLink: string; 
    googleLink: string; 
    linkedinLink: string;
    companyName: string;    
}

