import { Component } from "@angular/core";

@Component({
  selector: "app-videoIntro",
  templateUrl: "./videoIntro.component.html",
  styleUrls: ["./videoIntro.component.scss"],
})
export class VideoIntroComponent {
  public ngOnInit(){
    document.body.style.overflow = 'hidden';

    let videoElem = document.getElementById("videoElement")! as HTMLVideoElement;
   
    videoElem.muted = true;
    videoElem.play();

    setTimeout(() => {
      this.fadeOut(document.getElementById('videoIntro'), 1500);
    }, 7500);
  }

  public skipVideo(){
    this.fadeOut(document.getElementById('videoIntro'), 1000);
  }

  public fadeOut(element:any, speed:any) {
    try{
      var seconds = speed/1000;
      element.style.transition = "opacity "+seconds+"s ease";
  
      element.style.opacity = 0;
      setTimeout(function() {
        try{
          element.parentNode.removeChild(element);
        }catch{}
      }, speed);
      
      document.body.style.overflow = 'auto';
    }catch{}
  }
}
