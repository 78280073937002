import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as DicomActions from "./actions";
import { DicomService } from "src/app/services/api";
import * as FromDicom from "../dicom/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class DicomEffects {
  public loadManyDicom$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DicomActions.loadManyDicom),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.dicomService.dicomControllerGetManyDicom({page, limit, filters });
          return DicomActions.loadManyDicomSuccess({ manyDicom: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return DicomActions.loadManyDicomFailure();
        }
      }),
    ),
  );

  public updateDicom$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DicomActions.updateDicom),
      withLatestFrom(this.store.select(FromDicom.selectID)),
      mergeMap(async (action) => {
        const dicom = action[0].dicom; 
        try {
          const result: any = await this.dicomService.dicomControllerUpdateOne({
            dicom : dicom,
          });
          return DicomActions.updateDicomSuccess({ dicom: result });
        } catch (error: any) {
          console.error(error);
          return DicomActions.updateDicomFailure({ reason: error.message });
        }
      })
    )
  );

  public createDicom$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DicomActions.createDicom),
      withLatestFrom(this.store.select(FromDicom.selectID)),
      mergeMap(async (action) => {
        const dicom = action[0].dicom; 
        try {
          const result: any = await this.dicomService.dicomControllerCreateDicom({
            dicom : dicom,
          });
          return DicomActions.createDicomSuccess({ dicom: result });
        } catch (error: any) {
          console.error(error);
          return DicomActions.createDicomFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteReakCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DicomActions.deleteDicom),
      withLatestFrom(this.store.select(FromDicom.selectID)),
      mergeMap(async (action) => {
        const dicom = action[0].dicom; 
        try {
          const result: any = await this.dicomService.dicomControllerDeleteDicom({
            dicom : dicom,
          });
          return DicomActions.deleteDicomSuccess({ dicomId: result.id });
        } catch (error: any) {
          console.error(error);
          return DicomActions.deleteDicomFailure({ reason: error.message });
        }
      })
    ),
  );

  public getDicom$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DicomActions.getDicom),
      withLatestFrom(this.store.select(FromDicom.selectID)),
      mergeMap(async (action) => {
        const dicom = action[0].dicom; 
        try {
          const result: any = await this.dicomService.dicomControllerGetDicom({
            dicom : dicom,
          });
          return DicomActions.getDicomSuccess({ dicom: result });
        } catch (error: any) {
          console.error(error);
          return DicomActions.getDicomFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private dicomService: DicomService, private store: Store<RootState>) {}
}
