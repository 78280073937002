import { createAction, props } from "@ngrx/store";
import { CartoCases } from "src/app/services/api";
import { CartoCasesControllerGetManyCartoCasesRequestParams } from "src/app/services/api/api/cartoCases.serviceInterface";

export const loadManyCartoCases = createAction("[CartoCases] Load Many CartoCases", props<CartoCasesControllerGetManyCartoCasesRequestParams>());
export const loadManyCartoCasesSuccess = createAction("[CartoCases] Load Many CartoCases Success", props<{ manyCartoCases: any, filtered: boolean }>());
export const loadManyCartoCasesFailure = createAction("[CartoCases] Load Many CartoCases Failure");

export const loadCartoCases = createAction("[CartoCases] Load CartoCases", props<{ cartoCasesId: string }>());
export const loadCartoCasesSuccess = createAction("[CartoCases] Load CartoCases Success", props<{ cartoCases: CartoCases }>());
export const loadCartoCasesFailure = createAction("[CartoCases] Load CartoCases Failure");

export const updateCartoCases = createAction("[CartoCases] Carto Cases Update", props<{ cartoCase: any }>());
export const updateCartoCasesSuccess = createAction("[CartoCases] Update Carto Cases Success", props<{ cartoCase: any }>());
export const updateCartoCasesFailure = createAction("[CartoCases] Update Carto Cases Failure", props<{ reason: string }>());

export const createCartoCases = createAction("[CartoCases] Carto Cases Create", props<{ cartoCase: any }>());
export const createCartoCasesSuccess = createAction("[CartoCases] Create Carto Cases Success", props<{ cartoCase: any }>());
export const createCartoCasesFailure = createAction("[CartoCases] Create Carto Cases Failure", props<{ reason: string }>());

export const deleteCartoCases = createAction("[CartoCases] Carto Cases Delete", props<{ cartoCase: any }>());
export const deleteCartoCasesSuccess = createAction("[CartoCases] Delete Carto Cases Success", props<{ cartoCaseId: any }>());
export const deleteCartoCasesFailure = createAction("[CartoCases] Delete Carto Cases Failure", props<{ reason: string }>());

export const getCartoCases = createAction("[CartoCases] Carto Cases get", props<{ cartoCase: any }>());
export const getCartoCasesSuccess = createAction("[CartoCases] get Carto Cases Success", props<{ cartoCase: any }>());
export const getCartoCasesFailure = createAction("[CartoCases] get Carto Cases Failure", props<{ reason: string }>());