import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as CartoCasesActions from "./actions";
import { CartoCasesService } from "src/app/services/api";
import * as FromCartoCases from "../cartoCases/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class CartoCasesEffects {
  public loadManyCartoCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(CartoCasesActions.loadManyCartoCases),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.cartoCasesService.cartoCasesControllerGetManyCartoCases({ page, limit, filters });
          return CartoCasesActions.loadManyCartoCasesSuccess({ manyCartoCases: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return CartoCasesActions.loadManyCartoCasesFailure();
        }
      }),
    ),
  );

  public updateCartoCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CartoCasesActions.updateCartoCases),
      withLatestFrom(this.store.select(FromCartoCases.selectID)),
      mergeMap(async (action) => {
        const cartoCase = action[0].cartoCase; 
        try {
          const result: any = await this.cartoCasesService.cartoCasesControllerUpdateOne({
            cartoCases : cartoCase,
          });
          return CartoCasesActions.updateCartoCasesSuccess({ cartoCase: result });
        } catch (error: any) {
          console.error(error);
          return CartoCasesActions.updateCartoCasesFailure({ reason: error.message });
        }
      })
    )
  );

  public createCartoCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CartoCasesActions.createCartoCases),
      withLatestFrom(this.store.select(FromCartoCases.selectID)),
      mergeMap(async (action) => {
        const cartoCase = action[0].cartoCase; 
        try {
          const result: any = await this.cartoCasesService.cartoCasesControllerCreateCartoCases({
            cartoCases : cartoCase,
          });
          return CartoCasesActions.createCartoCasesSuccess({ cartoCase: result });
        } catch (error: any) {
          console.error(error);
          return CartoCasesActions.createCartoCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteReakCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CartoCasesActions.deleteCartoCases),
      withLatestFrom(this.store.select(FromCartoCases.selectID)),
      mergeMap(async (action) => {
        const cartoCase = action[0].cartoCase; 
        try {
          const result: any = await this.cartoCasesService.cartoCasesControllerDeleteCartoCases({
            cartoCases : cartoCase,
          });
          return CartoCasesActions.deleteCartoCasesSuccess({ cartoCaseId: result.id });
        } catch (error: any) {
          console.error(error);
          return CartoCasesActions.deleteCartoCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  public getCartoCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CartoCasesActions.getCartoCases),
      withLatestFrom(this.store.select(FromCartoCases.selectID)),
      mergeMap(async (action) => {
        const cartoCase = action[0].cartoCase; 
        try {
          const result: any = await this.cartoCasesService.cartoCasesControllerGetCartoCases({
            cartoCases : cartoCase,
          });
          return CartoCasesActions.getCartoCasesSuccess({ cartoCase: result });
        } catch (error: any) {
          console.error(error);
          return CartoCasesActions.getCartoCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private cartoCasesService: CartoCasesService, private store: Store<RootState>) {}
}
