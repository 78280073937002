import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as RealCasesActions from "./actions";
import { RealCasesService } from "src/app/services/api";
import * as FromRealCases from "../realCases/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class RealCasesEffects {
  public loadManyRealCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(RealCasesActions.loadManyRealCases),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.realCasesService.realCasesControllerGetManyRealCases({ page, limit, filters });
          return RealCasesActions.loadManyRealCasesSuccess({ manyRealCases: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return RealCasesActions.loadManyRealCasesFailure();
        }
      }),
    ),
  );

  public updateRealCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealCasesActions.updateRealCases),
      withLatestFrom(this.store.select(FromRealCases.selectID)),
      mergeMap(async (action) => {
        const realCase = action[0].realCase; 
        try {
          const result: any = await this.realCasesService.realCasesControllerUpdateOne({
            realCases : realCase,
          });
          return RealCasesActions.updateRealCasesSuccess({ realCase: result });
        } catch (error: any) {
          console.error(error);
          return RealCasesActions.updateRealCasesFailure({ reason: error.message });
        }
      })
    )
  );

  public createRealCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealCasesActions.createRealCases),
      withLatestFrom(this.store.select(FromRealCases.selectID)),
      mergeMap(async (action) => {
        const realCase = action[0].realCase; 
        try {
          const result: any = await this.realCasesService.realCasesControllerCreateRealCases({
            realCases : realCase,
          });
          return RealCasesActions.createRealCasesSuccess({ realCase: result });
        } catch (error: any) {
          console.error(error);
          return RealCasesActions.createRealCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteReakCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealCasesActions.deleteRealCases),
      withLatestFrom(this.store.select(FromRealCases.selectID)),
      mergeMap(async (action) => {
        const realCase = action[0].realCase; 
        try {
          const result: any = await this.realCasesService.realCasesControllerDeleteRealCases({
            realCases : realCase,
          });
          return RealCasesActions.deleteRealCasesSuccess({ realCaseId: result.id });
        } catch (error: any) {
          console.error(error);
          return RealCasesActions.deleteRealCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  public getRealCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RealCasesActions.getRealCases),
      withLatestFrom(this.store.select(FromRealCases.selectID)),
      mergeMap(async (action) => {
        const realCase = action[0].realCase; 
        try {
          const result: any = await this.realCasesService.realCasesControllerGetRealCases({
            realCases : realCase,
          });
          return RealCasesActions.getRealCasesSuccess({ realCase: result });
        } catch (error: any) {
          console.error(error);
          return RealCasesActions.getRealCasesFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private realCasesService: RealCasesService, private store: Store<RootState>) {}
}
