import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "src/app/constants";
import { CartoCases } from "src/app/services/api";
import { RootState } from "..";
import { ManyCartoCases } from "./state";

export const selectFeature = (state: RootState): ManyCartoCases => state.cartoCases.manyCartoCases;

export const selectID = (state: RootState): any => state.cartoCases.id;

export const selectAll = createSelector(selectFeature, (manyCartoCases: ManyCartoCases) => Object.values(manyCartoCases).filter(Boolean));

export const selectPaged = createSelector(selectAll, (cartoCases: CartoCases[], page: number) =>
  cartoCases
    .sort((a: CartoCases, b: CartoCases) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyCartoCases: CartoCases[], id: string) => manyCartoCases.find(cartoCases => cartoCases.id === id));

export const selectLoading = (state: RootState): boolean => state.cartoCases.loading;

export const selectTotal = (state: RootState): number => state.cartoCases.total;

export const selectCurrentCartoCase = (state: RootState): any => state.cartoCases.currentCartoCase;

export const selectRelatedCartoCases = (state: RootState): ManyCartoCases => state.cartoCases.manyCartoCases;

export const selectRelatedCartoCasesArray = (state: RootState): CartoCases[] => state.cartoCases.manyCartoCasesArray;

export const selectArrayFilteredCartoCases = (state: RootState): CartoCases[] => state.cartoCases.manyCartoCasesArray;

export const selectFilteredCartoCases = (categories: string[]):
MemoizedSelector<RootState, CartoCases[], DefaultProjectorFn<CartoCases[]>> =>
  createSelector(selectRelatedCartoCases, (news: ManyCartoCases) =>{
    let val : CartoCases[] = Object.values(news); 
    let cartoCasesFiltered : CartoCases[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          cartoCasesFiltered.push(element);
        }
      });
    });   
    return cartoCasesFiltered;
  }  
);

export const selectFilteredCartoCasesArray = (categories: string[]):
MemoizedSelector<RootState, CartoCases[], DefaultProjectorFn<CartoCases[]>> =>
  createSelector(selectRelatedCartoCasesArray, (cartoCases: CartoCases[]) =>{
    let cartoCasesFiltered : CartoCases[] = [];        
    cartoCases.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          cartoCasesFiltered.push(element);
        }
      });
    }); 
    return cartoCasesFiltered;
  }  
);

export const selectFilteredSearchCartoCasesArray = (text: string): 
  MemoizedSelector<RootState, CartoCases[], DefaultProjectorFn<CartoCases[]>> =>
  createSelector(selectRelatedCartoCasesArray, (cartoCases: CartoCases[]) =>{
    let cartoCasesFiltered : CartoCases[] = [];        
    cartoCases.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        cartoCasesFiltered.push(element);
      }
    });   
    return cartoCasesFiltered;
  }   
  );