import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as ScienceInsightsActions from "./actions";
import { ScienceInsights } from "src/app/services/api";

export const scienceInsightsReducer = createReducer(
  initialState,

  on(ScienceInsightsActions.loadManyScienceInsights, ScienceInsightsActions.loadScienceInsights, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(ScienceInsightsActions.loadManyScienceInsightsFailure, ScienceInsightsActions.loadScienceInsightsFailure, state => ({
    ...state,
    loading: false,
  })),

  on(ScienceInsightsActions.loadManyScienceInsightsSuccess, (state, { manyScienceInsights, filtered }) => {
    const newScienceInsights: { [key: string]: ScienceInsights } = {};
    // Check query petition result
    if(manyScienceInsights.scienceinsights != undefined && manyScienceInsights.scienceinsights.length > 0){
      
      if(!filtered){
        let scienceInsightsArray = manyScienceInsights.scienceinsights[0];
        Object.keys(scienceInsightsArray).map((key: string) => {

          newScienceInsights[key!] = scienceInsightsArray[key];
        });
      }else{
        manyScienceInsights.scienceinsights.forEach((scienceInsights: ScienceInsights) => {
          newScienceInsights[scienceInsights.id!] = scienceInsights;
        });
      }
      let lastTotal = 0;
      if(manyScienceInsights.isFiltered){
        if(manyScienceInsights.scienceinsights != undefined && manyScienceInsights.scienceinsights.length > 0){
          lastTotal = Object.keys(newScienceInsights).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyScienceInsights).forEach(element => {
        if(newScienceInsights[element] == undefined){
          newScienceInsights[element] = state.manyScienceInsights[element];
        }
      });
      let manyScienceInsightsArrayAux : ScienceInsights[] = [...state.manyScienceInsightsArray];
      Object.values(newScienceInsights).forEach(scienceInsights => {
        let inserted : boolean = false;
        manyScienceInsightsArrayAux.forEach(element => {
          if(element.id == scienceInsights.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyScienceInsightsArrayAux = [...manyScienceInsightsArrayAux, scienceInsights]
        }
      });
      return {
        ...state,
        total: manyScienceInsightsArrayAux.length,
        manyScienceInsights: {
          ...newScienceInsights
        },
        manyScienceInsightsArray: manyScienceInsightsArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(ScienceInsightsActions.loadScienceInsightsSuccess, (state, { scienceInsights }) => {
    return {
      ...state,
      manyScienceInsights: {
        ...state.manyScienceInsights,
        [scienceInsights.id!]: scienceInsights,
      },
      loading: false,
    };
  }),

  on(ScienceInsightsActions.updateScienceInsightsSuccess, (state, { scienceInsights}) => {
    return {
      ...state,
      loading: false,
      category : scienceInsights.category,
      createdAt: scienceInsights.createdAt,
      description : scienceInsights.description,
      duration: scienceInsights.duration,
      id: scienceInsights.id,
      imagePreviewLink: scienceInsights.imagePreviewLink,
      link: scienceInsights.link,
      title: scienceInsights.title,
      type: scienceInsights.type,
      views: scienceInsights.views,
      likes : scienceInsights.likes,
      dislikes : scienceInsights.dislikes,
    }
  }),

  on(ScienceInsightsActions.updateScienceInsightsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  
  on(ScienceInsightsActions.getScienceInsightsSuccess, (state, { scienceInsights }) => {
    return {
      ...state,
      currentScienceInsights: scienceInsights[0],
      loading: false,
    }
  }),
  on(ScienceInsightsActions.getScienceInsightsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(ScienceInsightsActions.deleteScienceInsightsSuccess, (state, { scienceInsightsId }) => {
    const manyScienceInsights = Object.values(state.manyScienceInsights).filter(scienceInsights => scienceInsights.id !== scienceInsightsId);
    const newScienceInsights: { [key: string]: ScienceInsights } = {};
    manyScienceInsights.forEach((scienceInsights: ScienceInsights) => (newScienceInsights[scienceInsights.id!] = scienceInsights));
    return {
      ...state, 
      manyScienceInsightsArray: manyScienceInsights,
      manyScienceInsights: newScienceInsights,
      loading: false,
    };  
  }),

  on(ScienceInsightsActions.deleteScienceInsightsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
