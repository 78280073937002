import { InteractiveImages } from "src/app/services/api";

export const persistedKeys = ["total","manyInteractiveImages"];

export interface ManyInteractiveImages {
  [id: string]: InteractiveImages;
}

export interface InteractiveImagesState {
  currentInteractiveImages: any;
  manyInteractiveImages: ManyInteractiveImages;
  manyInteractiveImagesArray: InteractiveImages[];
  loading: boolean;
  total: number;
  id:string;
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: InteractiveImagesState = {
  currentInteractiveImages: {},
  manyInteractiveImages: {},
  manyInteractiveImagesArray: [],
  loading: false,
  total: 0,
  id:"",
  likes: 0,
  dislikes: 0,
  views: 0
};
