import { Language } from "../enums";

export const DEFAULT_LANGUAGE = Language.en;

export const PAGE_LIMIT = 5;

export const COUNTRIES = [
  {
    en: "Afghanistan",
    es: "Afganistán",
    dialCode: "+93",
    code: "AF",
  },
  {
    en: "Albania",
    es: "Albania",
    dialCode: "+355",
    code: "AL",
  },
  {
    en: "Algeria",
    es: "Argelia",
    dialCode: "+213",
    code: "DZ",
  },
  {
    en: "American Samoa",
    es: "Samoa Americana",
    dialCode: "+1684",
    code: "AS",
  },
  {
    en: "Andorra",
    es: "Andorra",
    dialCode: "+376",
    code: "AD",
  },
  {
    en: "Angola",
    es: "Angola",
    dialCode: "+244",
    code: "AO",
  },
  {
    en: "Anguilla",
    es: "Anguilla",
    dialCode: "+1264",
    code: "AI",
  },
  {
    en: "Antarctica",
    es: "Antártida",
    dialCode: "+672",
    code: "AQ",
  },
  {
    en: "Antigua and Barbuda",
    es: "Antigua y Barbuda",
    dialCode: "+1268",
    code: "AG",
  },
  {
    en: "Argentina",
    es: "Argentina",
    dialCode: "+54",
    code: "AR",
  },
  {
    en: "Armenia",
    es: "Armenia",
    dialCode: "+374",
    code: "AM",
  },
  {
    en: "Aruba",
    es: "Aruba",
    dialCode: "+297",
    code: "AW",
  },
  {
    en: "Australia",
    es: "Australia",
    dialCode: "+61",
    code: "AU",
  },
  {
    en: "Austria",
    es: "Austria",
    dialCode: "+43",
    code: "AT",
  },
  {
    en: "Azerbaijan",
    es: "Azerbaiyán",
    dialCode: "+994",
    code: "AZ",
  },
  {
    en: "Bahamas",
    es: "Bahamas",
    dialCode: "+1242",
    code: "BS",
  },
  {
    en: "Bahrain",
    es: "Baréin",
    dialCode: "+973",
    code: "BH",
  },
  {
    en: "Bangladesh",
    es: "Banglades",
    dialCode: "+880",
    code: "BD",
  },
  {
    en: "Barbados",
    es: "Barbados",
    dialCode: "+1246",
    code: "BB",
  },
  {
    en: "Belarus",
    es: "Bielorrusia",
    dialCode: "+375",
    code: "BY",
  },
  {
    en: "Belgium",
    es: "Bélgica",
    dialCode: "+32",
    code: "BE",
  },
  {
    en: "Belize",
    es: "Belice",
    dialCode: "+501",
    code: "BZ",
  },
  {
    en: "Benin",
    es: "Benin",
    dialCode: "+229",
    code: "BJ",
  },
  {
    en: "Bermuda",
    es: "Bermudas",
    dialCode: "+1441",
    code: "BM",
  },
  {
    en: "Bhutan",
    es: "Butan",
    dialCode: "+975",
    code: "BT",
  },
  {
    en: "Bolivia",
    es: "Bolivia",
    dialCode: "+591",
    code: "BO",
  },
  {
    en: "Bosnia and Herzegovina",
    es: "Bosnia-Herzegovina",
    dialCode: "+387",
    code: "BA",
  },
  {
    en: "Botswana",
    es: "Botsuana",
    dialCode: "+267",
    code: "BW",
  },
  {
    en: "Brazil",
    es: "Brasil",
    dialCode: "+55",
    code: "BR",
  },
  {
    en: "British Indian Ocean Territory",
    es: "Territorio Británico del Océano Índico",
    dialCode: "+246",
    code: "IO",
  },
  {
    en: "Brunei Darussalam",
    es: "Brunei",
    dialCode: "+673",
    code: "BN",
  },
  {
    en: "Bulgaria",
    es: "Bulgaria",
    dialCode: "+359",
    code: "BG",
  },
  {
    en: "Burkina Faso",
    es: "Burkina Faso",
    dialCode: "+226",
    code: "BF",
  },
  {
    en: "Burundi",
    es: "Burundi",
    dialCode: "+257",
    code: "BI",
  },
  {
    en: "Cambodia",
    es: "Camboya",
    dialCode: "+855",
    code: "KH",
  },
  {
    en: "Cameroon",
    es: "Camerún",
    dialCode: "+237",
    code: "CM",
  },
  {
    en: "Canada",
    es: "Canadá",
    dialCode: "+1",
    code: "CA",
  },
  {
    en: "Cape Verde",
    es: "Cabo Verde",
    dialCode: "+238",
    code: "CV",
  },
  {
    en: "Cayman Islands",
    es: "Islas Caimán",
    dialCode: "+ 345",
    code: "KY",
  },
  {
    en: "Central African Republic",
    es: "República Centroafricana",
    dialCode: "+236",
    code: "CF",
  },
  {
    en: "Chad",
    es: "Chad",
    dialCode: "+235",
    code: "TD",
  },
  {
    en: "Chile",
    es: "Chile",
    dialCode: "+56",
    code: "CL",
  },
  {
    en: "China",
    es: "China",
    dialCode: "+86",
    code: "CN",
  },
  {
    en: "Christmas Island",
    es: "Isla de Navidad",
    dialCode: "+61",
    code: "CX",
  },
  {
    en: "Cocos (Keeling) Islands",
    es: "Islas Cocos",
    dialCode: "+61",
    code: "CC",
  },
  {
    en: "Colombia",
    es: "Colombia",
    dialCode: "+57",
    code: "CO",
  },
  {
    en: "Comoros",
    es: "Comoras",
    dialCode: "+269",
    code: "KM",
  },
  {
    en: "Congo",
    es: "Congo",
    dialCode: "+242",
    code: "CG",
  },
  {
    en: "Congo, The Democratic Republic of the",
    es: "República Democrática del Congo",
    dialCode: "+243",
    code: "CD",
  },
  {
    en: "Cook Islands",
    es: "Islas Cook",
    dialCode: "+682",
    code: "CK",
  },
  {
    en: "Costa Rica",
    es: "Costa Rica",
    dialCode: "+506",
    code: "CR",
  },
  {
    en: "Cote d'Ivoire",
    es: "Costa de Marfil",
    dialCode: "+225",
    code: "CI",
  },
  {
    en: "Croatia",
    es: "Croacia",
    dialCode: "+385",
    code: "HR",
  },
  {
    en: "Cuba",
    es: "Cuba",
    dialCode: "+53",
    code: "CU",
  },
  {
    en: "Cyprus",
    es: "Chipre",
    dialCode: "+537",
    code: "CY",
  },
  {
    en: "Czechia",
    es: "Chequia",
    dialCode: "+420",
    code: "CZ",
  },
  {
    en: "Denmark",
    es: "Dinamarca",
    dialCode: "+45",
    code: "DK",
  },
  {
    en: "Djibouti",
    es: "Yibuti",
    dialCode: "+253",
    code: "DJ",
  },
  {
    en: "Dominica",
    es: "Dominica",
    dialCode: "+1767",
    code: "DM",
  },
  {
    en: "Dominican Republic",
    es: "República Dominicana",
    dialCode: "+1849",
    code: "DO",
  },
  {
    en: "Ecuador",
    es: "Ecuador",
    dialCode: "+593",
    code: "EC",
  },
  {
    en: "Egypt",
    es: "Egipto",
    dialCode: "+20",
    code: "EG",
  },
  {
    en: "El Salvador",
    es: "El Salvador",
    dialCode: "+503",
    code: "SV",
  },
  {
    en: "Equatorial Guinea",
    es: "Guinea Ecuatorial",
    dialCode: "+240",
    code: "GQ",
  },
  {
    en: "Eritrea",
    es: "Eritrea",
    dialCode: "+291",
    code: "ER",
  },
  {
    en: "Estonia",
    es: "Estonia",
    dialCode: "+372",
    code: "EE",
  },
  {
    en: "Ethiopia",
    es: "Etiopía",
    dialCode: "+251",
    code: "ET",
  },
  {
    en: "Falkland Islands (Malvinas)",
    es: "Islas Malvinas",
    dialCode: "+500",
    code: "FK",
  },
  {
    en: "Faroe Islands",
    es: "Islas Feroe",
    dialCode: "+298",
    code: "FO",
  },
  {
    en: "Fiji",
    es: "Fiyi",
    dialCode: "+679",
    code: "FJ",
  },
  {
    en: "Finland",
    es: "Finlandia",
    dialCode: "+358",
    code: "FI",
  },
  {
    en: "France",
    es: "Francia",
    dialCode: "+33",
    code: "FR",
  },
  {
    en: "French Guiana",
    es: "Guayana Francesa",
    dialCode: "+594",
    code: "GF",
  },
  {
    en: "French Polynesia",
    es: "Polinesia Francesa",
    dialCode: "+689",
    code: "PF",
  },
  {
    en: "Gabon",
    es: "Gabón",
    dialCode: "+241",
    code: "GA",
  },
  {
    en: "Gambia",
    es: "Gambia",
    dialCode: "+220",
    code: "GM",
  },
  {
    en: "Georgia",
    es: "Georgia",
    dialCode: "+995",
    code: "GE",
  },
  {
    en: "Germany",
    es: "Alemania",
    dialCode: "+49",
    code: "DE",
  },
  {
    en: "Ghana",
    es: "Ghana",
    dialCode: "+233",
    code: "GH",
  },
  {
    en: "Gibraltar",
    es: "Gibraltar",
    dialCode: "+350",
    code: "GI",
  },
  {
    en: "Greece",
    es: "Grecia",
    dialCode: "+30",
    code: "GR",
  },
  {
    en: "Greenland",
    es: "Groenlandia",
    dialCode: "+299",
    code: "GL",
  },
  {
    en: "Grenada",
    es: "Granada",
    dialCode: "+1473",
    code: "GD",
  },
  {
    en: "Guadeloupe",
    es: "Guadalupe",
    dialCode: "+590",
    code: "GP",
  },
  {
    en: "Guam",
    es: "Guam",
    dialCode: "+1671",
    code: "GU",
  },
  {
    en: "Guatemala",
    es: "Guatemala",
    dialCode: "+502",
    code: "GT",
  },
  {
    en: "Guernsey",
    es: "Guernsey",
    dialCode: "+44",
    code: "GG",
  },
  {
    en: "Guinea",
    es: "Guinea",
    dialCode: "+224",
    code: "GN",
  },
  {
    en: "Guinea-Bissau",
    es: "Guinea-Bisau",
    dialCode: "+245",
    code: "GW",
  },
  {
    en: "Guyana",
    es: "Guyana",
    dialCode: "+595",
    code: "GY",
  },
  {
    en: "Haiti",
    es: "Haití",
    dialCode: "+509",
    code: "HT",
  },
  {
    en: "Holy See (Vatican City State)",
    es: "Ciudad del Vaticano",
    dialCode: "+379",
    code: "VA",
  },
  {
    en: "Honduras",
    es: "Honduras",
    dialCode: "+504",
    code: "HN",
  },
  {
    en: "Hong Kong",
    es: "Hong Kong",
    dialCode: "+852",
    code: "HK",
  },
  {
    en: "Hungary",
    es: "Hungría",
    dialCode: "+36",
    code: "HU",
  },
  {
    en: "Iceland",
    es: "Islandia",
    dialCode: "+354",
    code: "IS",
  },
  {
    en: "India",
    es: "India",
    dialCode: "+91",
    code: "IN",
  },
  {
    en: "Indonesia",
    es: "Indonesia",
    dialCode: "+62",
    code: "ID",
  },
  {
    en: "Iran, Islamic Republic of",
    es: "Irán",
    dialCode: "+98",
    code: "IR",
  },
  {
    en: "Iraq",
    es: "Iraq",
    dialCode: "+964",
    code: "IQ",
  },
  {
    en: "Ireland",
    es: "Irlanda",
    dialCode: "+353",
    code: "IE",
  },
  {
    en: "Isle of Man",
    es: "Isla de Man",
    dialCode: "+44",
    code: "IM",
  },
  {
    en: "Israel",
    es: "Israel",
    dialCode: "+972",
    code: "IL",
  },
  {
    en: "Italy",
    es: "Italia",
    dialCode: "+39",
    code: "IT",
  },
  {
    en: "Jamaica",
    es: "Jamaica",
    dialCode: "+1876",
    code: "JM",
  },
  {
    en: "Japan",
    es: "Japón",
    dialCode: "+81",
    code: "JP",
  },
  {
    en: "Jersey",
    es: "Jersey",
    dialCode: "+44",
    code: "JE",
  },
  {
    en: "Jordan",
    es: "Jordania",
    dialCode: "+962",
    code: "JO",
  },
  {
    en: "Kazakhstan",
    es: "Kazajistán",
    dialCode: "+7",
    code: "KZ",
  },
  {
    en: "Kenya",
    es: "Kenia",
    dialCode: "+254",
    code: "KE",
  },
  {
    en: "Kiribati",
    es: "Kiribati",
    dialCode: "+686",
    code: "KI",
  },
  {
    en: "Korea, Democratic People's Republic of",
    es: "Corea del Norte",
    dialCode: "+850",
    code: "KP",
  },
  {
    en: "Korea, Republic of",
    es: "Corea del Sur",
    dialCode: "+82",
    code: "KR",
  },
  {
    en: "Kosovo",
    es: "Kosovo",
    dialCode: "+383",
    code: "XK",
  },
  {
    en: "Kuwait",
    es: "Kuwait",
    dialCode: "+965",
    code: "KW",
  },
  {
    en: "Kyrgyzstan",
    es: "Kirguistán",
    dialCode: "+996",
    code: "KG",
  },
  {
    en: "Lao People's Democratic Republic",
    es: "Laos",
    dialCode: "+856",
    code: "LA",
  },
  {
    en: "Latvia",
    es: "Letonia",
    dialCode: "+371",
    code: "LV",
  },
  {
    en: "Lebanon",
    es: "Líbano",
    dialCode: "+961",
    code: "LB",
  },
  {
    en: "Lesotho",
    es: "Lesoto",
    dialCode: "+266",
    code: "LS",
  },
  {
    en: "Liberia",
    es: "Liberia",
    dialCode: "+231",
    code: "LR",
  },
  {
    en: "Libyan Arab Jamahiriya",
    es: "Libia",
    dialCode: "+218",
    code: "LY",
  },
  {
    en: "Liechtenstein",
    es: "Liechtenstein",
    dialCode: "+423",
    code: "LI",
  },
  {
    en: "Lithuania",
    es: "Lituania",
    dialCode: "+370",
    code: "LT",
  },
  {
    en: "Luxembourg",
    es: "Luxemburgo",
    dialCode: "+352",
    code: "LU",
  },
  {
    en: "Macao",
    es: "Macao",
    dialCode: "+853",
    code: "MO",
  },
  {
    en: "Macedonia, The Former Yugoslav Republic of",
    es: "República de Macedonia",
    dialCode: "+389",
    code: "MK",
  },
  {
    en: "Madagascar",
    es: "Madagascar",
    dialCode: "+261",
    code: "MG",
  },
  {
    en: "Malawi",
    es: "Malaui",
    dialCode: "+265",
    code: "MW",
  },
  {
    en: "Malaysia",
    es: "Malasia",
    dialCode: "+60",
    code: "MY",
  },
  {
    en: "Maldives",
    es: "Maldivas",
    dialCode: "+960",
    code: "MV",
  },
  {
    en: "Mali",
    es: "Malí",
    dialCode: "+223",
    code: "ML",
  },
  {
    en: "Malta",
    es: "Malta",
    dialCode: "+356",
    code: "MT",
  },
  {
    en: "Marshall Islands",
    es: "Islas Marshall",
    dialCode: "+692",
    code: "MH",
  },
  {
    en: "Martinique",
    es: "Martinica",
    dialCode: "+596",
    code: "MQ",
  },
  {
    en: "Mauritania",
    es: "Mauritania",
    dialCode: "+222",
    code: "MR",
  },
  {
    en: "Mauritius",
    es: "Mauricio",
    dialCode: "+230",
    code: "MU",
  },
  {
    en: "Mayotte",
    es: "Mayotte",
    dialCode: "+262",
    code: "YT",
  },
  {
    en: "Mexico",
    es: "México",
    dialCode: "+52",
    code: "MX",
  },
  {
    en: "Micronesia, Federated States of",
    es: "Estados Federados de Micronesia",
    dialCode: "+691",
    code: "FM",
  },
  {
    en: "Moldova, Republic of",
    es: "Moldavia",
    dialCode: "+373",
    code: "MD",
  },
  {
    en: "Monaco",
    es: "Monaco",
    dialCode: "+377",
    code: "MC",
  },
  {
    en: "Mongolia",
    es: "Mongolia",
    dialCode: "+976",
    code: "MN",
  },
  {
    en: "Montenegro",
    es: "Montenegro",
    dialCode: "+382",
    code: "ME",
  },
  {
    en: "Montserrat",
    es: "Montserrat",
    dialCode: "+1664",
    code: "MS",
  },
  {
    en: "Morocco",
    es: "Marruecos",
    dialCode: "+212",
    code: "MA",
  },
  {
    en: "Mozambique",
    es: "Mozambique",
    dialCode: "+258",
    code: "MZ",
  },
  {
    en: "Myanmar",
    es: "Birmania",
    dialCode: "+95",
    code: "MM",
  },
  {
    en: "Namibia",
    es: "Namibia",
    dialCode: "+264",
    code: "NA",
  },
  {
    en: "Nauru",
    es: "Nauru",
    dialCode: "+674",
    code: "NR",
  },
  {
    en: "Nepal",
    es: "Nepal",
    dialCode: "+977",
    code: "NP",
  },
  {
    en: "Netherlands",
    es: "Holanda",
    dialCode: "+31",
    code: "NL",
  },
  {
    en: "Netherlands Antilles",
    es: "Antillas Holandesas",
    dialCode: "+599",
    code: "AN",
  },
  {
    en: "New Caledonia",
    es: "Nueva Caledonia",
    dialCode: "+687",
    code: "NC",
  },
  {
    en: "New Zealand",
    es: "Nueva Zelanda",
    dialCode: "+64",
    code: "NZ",
  },
  {
    en: "Nicaragua",
    es: "Nicaragua",
    dialCode: "+505",
    code: "NI",
  },
  {
    en: "Niger",
    es: "Niger",
    dialCode: "+227",
    code: "NE",
  },
  {
    en: "Nigeria",
    es: "Nigeria",
    dialCode: "+234",
    code: "NG",
  },
  {
    en: "Niue",
    es: "Niue",
    dialCode: "+683",
    code: "NU",
  },
  {
    en: "NorfolkIsland",
    es: "IslaNorfolk",
    dialCode: "+672",
    code: "NF",
  },
  {
    en: "NorthernMarianaIslands",
    es: "IslasMarianasdelNorte",
    dialCode: "+1670",
    code: "MP",
  },
  {
    en: "Norway",
    es: "Noruega",
    dialCode: "+47",
    code: "NO",
  },
  {
    en: "Oman",
    es: "Omán",
    dialCode: "+968",
    code: "OM",
  },
  {
    en: "Pakistan",
    es: "Pakistán",
    dialCode: "+92",
    code: "PK",
  },
  {
    en: "Palau",
    es: "Palaos",
    dialCode: "+680",
    code: "PW",
  },
  {
    en: "Panama",
    es: "Panamá",
    dialCode: "+507",
    code: "PA",
  },
  {
    en: "Papua New Guinea",
    es: "Papúa Nueva Guinea",
    dialCode: "+675",
    code: "PG",
  },
  {
    en: "Paraguay",
    es: "Paraguay",
    dialCode: "+595",
    code: "PY",
  },
  {
    en: "Peru",
    es: "Perú",
    dialCode: "+51",
    code: "PE",
  },
  {
    en: "Philippines",
    es: "Filipinas",
    dialCode: "+63",
    code: "PH",
  },
  {
    en: "Pitcairn",
    es: "Islas Pitcairn",
    dialCode: "+872",
    code: "PN",
  },
  {
    en: "Poland",
    es: "Polonia",
    dialCode: "+48",
    code: "PL",
  },
  {
    en: "Portugal",
    es: "Portugal",
    dialCode: "+351",
    code: "PT",
  },
  {
    en: "Puerto Rico",
    es: "Puerto Rico",
    dialCode: "+1939",
    code: "PR",
  },
  {
    en: "Qatar",
    es: "Qatar",
    dialCode: "+974",
    code: "QA",
  },
  {
    en: "Romania",
    es: "Rumania",
    dialCode: "+40",
    code: "RO",
  },
  {
    en: "Russia",
    es: "Rusia",
    dialCode: "+7",
    code: "RU",
  },
  {
    en: "Rwanda",
    es: "Ruanda",
    dialCode: "+250",
    code: "RW",
  },
  {
    en: "Réunion",
    es: "Reunion",
    dialCode: "+262",
    code: "RE",
  },
  {
    en: "Saint Barthélemy",
    es: "San Bartolome",
    dialCode: "+590",
    code: "BL",
  },
  {
    en: "Saint Helena, Ascension and Tristan Da Cunha",
    es: "Santa Elena, Ascensión y Tristán de Acuña",
    dialCode: "+290",
    code: "SH",
  },
  {
    en: "Saint Kitts and Nevis",
    es: "San Cristóbal y Nieves",
    dialCode: "+1869",
    code: "KN",
  },
  {
    en: "Saint Lucia",
    es: "Santa Lucía",
    dialCode: "+1758",
    code: "LC",
  },
  {
    en: "Saint Martin",
    es: "Isla de San Martín",
    dialCode: "+590",
    code: "MF",
  },
  {
    en: "Saint Pierre and Miquelon",
    es: "San Pedro y Miquelon",
    dialCode: "+508",
    code: "PM",
  },
  {
    en: "Saint Vincent and the Grenadines",
    es: "San Vicente y las Granadinas",
    dialCode: "+1784",
    code: "VC",
  },
  {
    en: "Samoa",
    es: "Samoa",
    dialCode: "+685",
    code: "WS",
  },
  {
    en: "San Marino",
    es: "San Marino",
    dialCode: "+378",
    code: "SM",
  },
  {
    en: "Sao Tome and Principe",
    es: "Santo Tomé y Príncipe",
    dialCode: "+239",
    code: "ST",
  },
  {
    en: "Saudi Arabia",
    es: "Arabia Saudita",
    dialCode: "+966",
    code: "SA",
  },
  {
    en: "Senegal",
    es: "Senegal",
    dialCode: "+221",
    code: "SN",
  },
  {
    en: "Serbia",
    es: "Serbia",
    dialCode: "+381",
    code: "RS",
  },
  {
    en: "Seychelles",
    es: "Seychelles",
    dialCode: "+248",
    code: "SC",
  },
  {
    en: "Sierra Leone",
    es: "Sierra Leona",
    dialCode: "+232",
    code: "SL",
  },
  {
    en: "Singapore",
    es: "Singapur",
    dialCode: "+65",
    code: "SG",
  },
  {
    en: "Slovakia",
    es: "Eslovaquia",
    dialCode: "+421",
    code: "SK",
  },
  {
    en: "Slovenia",
    es: "Eslovenia",
    dialCode: "+386",
    code: "SI",
  },
  {
    en: "Solomon Islands",
    es: "Islas Salomón",
    dialCode: "+677",
    code: "SB",
  },
  {
    en: "Somalia",
    es: "Somalia",
    dialCode: "+252",
    code: "SO",
  },
  {
    en: "South Africa",
    es: "Sudáfrica",
    dialCode: "+27",
    code: "ZA",
  },
  {
    en: "South Sudan",
    es: "Sudán del Sur",
    dialCode: "+211",
    code: "SS",
  },
  {
    en: "Spain",
    es: "España",
    dialCode: "+34",
    code: "ES",
  },
  {
    en: "Sri Lanka",
    es: "Sri Lanka",
    dialCode: "+94",
    code: "LK",
  },
  {
    en: "State of Palestine",
    es: "Estado de Palestina",
    dialCode: "+970",
    code: "PS",
  },
  {
    en: "Sudan",
    es: "Sudán",
    dialCode: "+249",
    code: "SD",
  },
  {
    en: "Suriname",
    es: "Surinam",
    dialCode: "+597",
    code: "SR",
  },
  {
    en: "Svalbard and Jan Mayen",
    es: "Svalbard y Jan Mayen",
    dialCode: "+47",
    code: "SJ",
  },
  {
    en: "Swaziland",
    es: "Suazilandia",
    dialCode: "+268",
    code: "SZ",
  },
  {
    en: "Sweden",
    es: "Suecia",
    dialCode: "+46",
    code: "SE",
  },
  {
    en: "Switzerland",
    es: "Suiza",
    dialCode: "+41",
    code: "CH",
  },
  {
    en: "Syrian Arab Republic",
    es: "Siria",
    dialCode: "+963",
    code: "SY",
  },
  {
    en: "Taiwan, Province of China",
    es: "Taiwán",
    dialCode: "+886",
    code: "TW",
  },
  {
    en: "Tayikistan",
    es: "Tayikistán",
    dialCode: "+992",
    code: "TJ",
  },
  {
    en: "Tanzania, United Republic of",
    es: "Tanzania",
    dialCode: "+255",
    code: "TZ",
  },
  {
    en: "Thailand",
    es: "Tailandia",
    dialCode: "+66",
    code: "TH",
  },
  {
    en: "Timor-Leste",
    es: "Timor Oriental",
    dialCode: "+670",
    code: "TL",
  },
  {
    en: "Togo",
    es: "Togo",
    dialCode: "+228",
    code: "TG",
  },
  {
    en: "Tokelau",
    es: "Tokelau",
    dialCode: "+690",
    code: "TK",
  },
  {
    en: "Tonga",
    es: "Tonga",
    dialCode: "+676",
    code: "TO",
  },
  {
    en: "Trinidad and Tobago",
    es: "Trinidad y Tobago",
    dialCode: "+1868",
    code: "TT",
  },
  {
    en: "Tunisia",
    es: "Túnez",
    dialCode: "+216",
    code: "TN",
  },
  {
    en: "Turkey",
    es: "Turquía",
    dialCode: "+90",
    code: "TR",
  },
  {
    en: "Turkmenistan",
    es: "Turkmenistán",
    dialCode: "+993",
    code: "TM",
  },
  {
    en: "Turks and Caicos Islands",
    es: "Islas Turcas y Caicos",
    dialCode: "+1649",
    code: "TC",
  },
  {
    en: "Tuvalu",
    es: "Tuvalu",
    dialCode: "+688",
    code: "TV",
  },
  {
    en: "Uganda",
    es: "Uganda",
    dialCode: "+256",
    code: "UG",
  },
  {
    en: "Ukraine",
    es: "Ucrania",
    dialCode: "+380",
    code: "UA",
  },
  {
    en: "United Arab Emirates",
    es: "Emiratos Árabes Unidos",
    dialCode: "+971",
    code: "AE",
  },
  {
    en: "United Kingdom",
    es: "Reino Unido",
    dialCode: "+44",
    code: "GB",
  },
  {
    en: "United States",
    es: "Estados Unidos",
    dialCode: "+1",
    code: "US",
  },
  {
    en: "Uruguay",
    es: "Uruguay",
    dialCode: "+598",
    code: "UY",
  },
  {
    en: "Uzbekistan",
    es: "Uzbekistán",
    dialCode: "+998",
    code: "UZ",
  },
  {
    en: "Vanuatu",
    es: "Vanuatu",
    dialCode: "+678",
    code: "VU",
  },
  {
    en: "Venezuela, Bolivarian Republic of",
    es: "Venezuela",
    dialCode: "+58",
    code: "VE",
  },
  {
    en: "Vietnam",
    es: "Vietnam",
    dialCode: "+84",
    code: "VN",
  },
  {
    en: "Virgin Islands, British",
    es: "Islas Vírgenes Británicas",
    dialCode: "+1284",
    code: "VG",
  },
  {
    en: "Virgin Islands, U.S.",
    es: "Islas Vírgenes de los Estados Unidos",
    dialCode: "+1340",
    code: "VI",
  },
  {
    en: "Wallis and Futuna",
    es: "Wallis y Futuna",
    dialCode: "+681",
    code: "WF",
  },
  {
    en: "Yemen",
    es: "Yemen",
    dialCode: "+967",
    code: "YE",
  },
  {
    en: "Zambia",
    es: "Zambia",
    dialCode: "+260",
    code: "ZM",
  },
  {
    en: "Zimbabwe",
    es: "Zimbabue",
    dialCode: "+263",
    code: "ZW",
  },
  {
    en: "Åland Islands",
    es: "Åland",
    dialCode: "+358",
    code: "AX",
  },
];
