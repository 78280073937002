import { createAction, props } from "@ngrx/store";
import { ScienceInsightsCategoryControllerGetManyScienceInsightsCategoriesRequestParams } from 
"src/app/services/api/api/scienceInsightsCategory.serviceInterface";
import { ScienceInsightsCategory } from "src/app/services/api/model/scienceInsightsCategory";

export const loadManyScienceInsightsCategories = createAction("[ScienceInsights Category] Load Many Science Insights categories", props<ScienceInsightsCategoryControllerGetManyScienceInsightsCategoriesRequestParams>());
export const loadManyScienceInsightsCategoriesSuccess = createAction("[ScienceInsights Category] Load Many Science Insights categories Success", props<{ manyScienceInsightsCategories: Array<ScienceInsightsCategory> }>());
export const loadManyScienceInsightsCategoriesFailure = createAction("[ScienceInsights Category] Load Many Science Insights categories Failure");

export const loadScienceInsightsCategory = createAction("[ScienceInsights Category] Load Science Insights Category", props<{ scienceInsightsCategoryId: string }>());
export const loadScienceInsightsCategorySuccess = createAction("[ScienceInsights Category] Load Science Insights Category Success", props<{ scienceInsightsCategory: ScienceInsightsCategory }>());
export const loadScienceInsightsCategoryFailure = createAction("[ScienceInsights Category] Load Science Insights Category Failure");