/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { from, Observable, of }                                        from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    UserServiceInterface,
    UserControllerCreateUserRequestParams,
    UserControllerDeleteUserRequestParams,
    UserControllerUpdateOneRequestParams,
    UserControllerRegisterUserRequestParams
} from './user.serviceInterface';
import { Md5 } from 'ts-md5';
import { child, endAt, equalTo, get, getDatabase, limitToFirst, onValue, orderByChild, orderByKey, push, query, ref, remove, set, startAt, update } from 'firebase/database';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';



@Injectable({
  providedIn: 'root'
})
export class UserService implements UserServiceInterface {

    //protected basePath = 'https://vrcardioexplorer-default-rtdb.firebaseio.com';
    protected basePath = environment.api;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration, public afAuth: AngularFireAuth) {
        if (configuration) {
            this.configuration = configuration;
        }
        this.configuration.basePath = this.basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userControllerCreateUser(requestParameters: UserControllerCreateUserRequestParams, 
        observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        
        const db = getDatabase();
        const postData : any = requestParameters.user;
        let hashName : string = Md5.hashStr(JSON.stringify(postData)).toUpperCase();
        let dbApiParts = environment.api.split("/");
        let dbType = dbApiParts[dbApiParts.length-1];
        let dbPath = dbType + `/users/` + hashName;
        postData.id = hashName;
        const newPostKey = push(child(ref(db), dbPath)).key;
        // Write the new post's data simultaneously in the posts list and the user's post list.
        const updates: { [key: string]: any } = {};
        updates[dbPath] = postData;
        return from(set(ref(getDatabase(), dbPath), 
            postData
        ).then((val) => {return postData}));
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async userControllerDeleteUser(requestParameters: UserControllerDeleteUserRequestParams): Promise<any> {
             
        let url = environment.deleteuser; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${requestParameters.uid}`
            },
            body: JSON.stringify(requestParameters) 
        };
    
        let result: any = await fetch(url, requestOptions);
        return result;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async userControllerUpdateOne(requestParameters: UserControllerUpdateOneRequestParams): Promise<Observable<any>> {
    
        /*
        let url = environment.getwebstats;
        let uid = "";
        const userRecord = await this.afAuth.signInWithEmailAndPassword(this.userData.email,this.userData.password).then(val=>{
          uid = val.user!.uid;
        })
        let result: any = await fetch(url,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${uid}` // Include the UID in the Authorization header
          }
        })*/
        
        let uid = "";
        const userRecord = await this.afAuth.signInWithEmailAndPassword(requestParameters.user.email,requestParameters.user.password).then( (val: any)=>{
          uid = val.user!.uid;
        })

        let url = environment.updateuser; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uid}`
            },
            body: JSON.stringify(requestParameters)
        };

         try {
            let response = await fetch(url, requestOptions);
            let result = await response.json();
            return result;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    public userControllerRegisterUser(requestParameters: UserControllerRegisterUserRequestParams): Observable<any> {
        let url = environment.registeruser; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(requestParameters) 
        };
    
        return from(fetch(url, requestOptions));
    }
}
