export enum Language {
  es = "es",
  en = "en",
}

export enum PageRoutes {
  news = "news",
  newId = "newid",
  ecg = "ecg",
  ecgId = "ecgId",
  login = "login",
  privacypolicy = "privacypolicy",
  downloadsoftware = "downloadsoftware",
  legalInformation = "legalinformation",
  signUp = "signup",
  resetPassword = "resetpassword",
  restorePassword = "restorepassword",
  collaborators = "collaborators",
  profile = "profile",
  development = "development",
  countdown = "countdown",
  developmentId = "developmentId",
  scienceInsights = "scienceinsights",
  scienceInsightsId = "scienceinsightsId",
  interactive = "interactive",
  interactiveId = "interactiveId",
  realCases = "realcases",
  realCasesId = "realcasesId",
  cartoCases = "cartocases",
  cartoCasesId = "cartocasesId",
  cardio3d = "cardio3d",
  cardio3dId = "cardio3dId",
  team = "team",
  swagger = "swagger",
  vrcardio = "vrcardio",
  webcontent = "webcontent",
  webstats = "webstats",
  users = "users",
  dicom = "dicom",
  updatesubscription = "updatesubscription",
  quiz = "quiz",
  smsverification = "smsverification"
}
