import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponentModule } from "../button/button.module";
import { HeaderComponent } from "./header.component";

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonComponentModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderComponentModule {}
