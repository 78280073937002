import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as InteractiveImagesActions from "./actions";
import { InteractiveImagesService } from "src/app/services/api";
import * as FromInteractiveImages from "../interactiveImages/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class InteractiveImagesEffects {
  public loadManyInteractiveImages$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(InteractiveImagesActions.loadManyInteractiveImages),
      mergeMap(async ({page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.interactiveImagesService.interactiveImagesControllerGetManyInteractiveImages
          ({ page, limit, filters });
          return InteractiveImagesActions.loadManyInteractiveImagesSuccess({ manyInteractiveImages: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return InteractiveImagesActions.loadManyInteractiveImagesFailure();
        }
      }),
    ),
  );

  public updateInteractiveImages$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(InteractiveImagesActions.updateInteractiveImages),
    withLatestFrom(this.store.select(FromInteractiveImages.selectID)),
    mergeMap(async (action) => {
      const interactiveImages = action[0].interactiveImages; 
      try {
        const result: any = await this.interactiveImagesService.interactiveImagesControllerUpdateOne({
          interactiveImages : interactiveImages,
        });
        return InteractiveImagesActions.updateInteractiveImagesSuccess({ interactiveImages: result });
      } catch (error: any) {
        console.error(error);
        return InteractiveImagesActions.updateInteractiveImagesFailure({ reason: error.message });
      }
    })
  )
);

public createInteractiveImages$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(InteractiveImagesActions.createInteractiveImages),
    withLatestFrom(this.store.select(FromInteractiveImages.selectID)),
    mergeMap(async (action) => {
      const interactiveImages = action[0].interactiveImages; 
      try {
        const result: any = await this.interactiveImagesService.interactiveImagesControllerCreateInteractiveImages({
          interactiveImages : interactiveImages,
        });
        return InteractiveImagesActions.createInteractiveImagesSuccess({ interactiveImages: result });
      } catch (error: any) {
        console.error(error);
        return InteractiveImagesActions.createInteractiveImagesFailure({ reason: error.message });
      }
    })
  ),
);

public deleteInteractiveImages$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(InteractiveImagesActions.deleteInteractiveImages),
    withLatestFrom(this.store.select(FromInteractiveImages.selectID)),
    mergeMap(async (action) => {
      const interactiveImages = action[0].interactiveImages; 
      try {
        const result: any = await this.interactiveImagesService.interactiveImagesControllerDeleteInteractiveImages({
          interactiveImages : interactiveImages,
        });
        return InteractiveImagesActions.deleteInteractiveImagesSuccess({ interactiveImagesId: result.id });
      } catch (error: any) {
        console.error(error);
        return InteractiveImagesActions.deleteInteractiveImagesFailure({ reason: error.message });
      }
    })
  ),
);

public getInteractiveImages$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(InteractiveImagesActions.getInteractiveImages),
    withLatestFrom(this.store.select(FromInteractiveImages.selectID)),
    mergeMap(async (action) => {
      const interactiveImages = action[0].interactiveImages; 
      try {
        const result: any = await this.interactiveImagesService.interactiveImagesControllerGetInteractiveImages({
          interactiveImages : interactiveImages,
        });
        return InteractiveImagesActions.getInteractiveImagesSuccess({ interactiveImages: result });
      } catch (error: any) {
        console.error(error);
        return InteractiveImagesActions.getInteractiveImagesFailure({ reason: error.message });
      }
    })
  ),
);

  constructor(private actions$: Actions, private interactiveImagesService: InteractiveImagesService, private store: Store<RootState>) {}
}
