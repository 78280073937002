import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as ImageCategoryActions from "./actions";
import { ImageCategory } from "src/app/services/api/model/imageCategory";
import { ImageCategoryService } from "src/app/services/api/api/imageCategory.service";

@Injectable()
export class ImageCategoryEffects {
  public loadManyImageCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(

      ofType(ImageCategoryActions.loadManyImageCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.imageCategoryService.imageCategoryControllerGetManyImageCategories({ page, limit}); 
          return ImageCategoryActions.loadManyImageCategoriesSuccess({ manyImageCategories: result });
        } catch (error) {
          console.error(error)
          return ImageCategoryActions.loadManyImageCategoriesFailure();
        }
      }),
    ),
  );
  constructor(private actions$: Actions, private imageCategoryService: ImageCategoryService) {}
}
