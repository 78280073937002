import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as SoftwareActions from "./actions";

export const softwareReducer = createReducer(
  initialState,

  on(SoftwareActions.loadSoftware, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(SoftwareActions.loadSoftwareFailure, state => ({
    ...state,
    loading: false,
  })),

  on(SoftwareActions.loadSoftwareSuccess, (state, { software }) => {
    return {
      ...state,
      currentSoftware: software[0],
      loading: false,
    };
  }),
);
