import { createAction, props } from "@ngrx/store";
import { InteractiveImages} from "src/app/services/api";
import { InteractiveImagesControllerGetManyInteractiveImagesRequestParams } from "src/app/services/api/api/interactiveImages.serviceInterface";

export const loadManyInteractiveImages = createAction("[InteractiveImages] Load Many InteractiveImages", props<InteractiveImagesControllerGetManyInteractiveImagesRequestParams>());
export const loadManyInteractiveImagesSuccess = createAction("[InteractiveImages] Load Many InteractiveImages Success", props<{ manyInteractiveImages: any, filtered:boolean }>());
export const loadManyInteractiveImagesFailure = createAction("[InteractiveImages] Load Many InteractiveImages Failure");

export const loadInteractiveImages = createAction("[InteractiveImages] Load InteractiveImages", props<{ interactiveImagesId: string }>());
export const loadInteractiveImagesSuccess = createAction("[InteractiveImages] Load InteractiveImages Success", props<{ interactiveImages: InteractiveImages }>());
export const loadInteractiveImagesFailure = createAction("[InteractiveImages] Load InteractiveImages Failure");

export const updateInteractiveImages = createAction("[InteractiveImages] InteractiveImages Update", props<{ interactiveImages: any }>());
export const updateInteractiveImagesSuccess = createAction("[InteractiveImages] Update InteractiveImages Success", props<{ interactiveImages: any }>());
export const updateInteractiveImagesFailure = createAction("[InteractiveImages] Update InteractiveImages Failure", props<{ reason: string }>());

export const createInteractiveImages = createAction("[InteractiveImages] InteractiveImages Create", props<{ interactiveImages: any }>());
export const createInteractiveImagesSuccess = createAction("[InteractiveImages] Create InteractiveImages Success", props<{ interactiveImages: any }>());
export const createInteractiveImagesFailure = createAction("[InteractiveImages] Create InteractiveImages Failure", props<{ reason: string }>());

export const deleteInteractiveImages = createAction("[InteractiveImages] InteractiveImages Delete", props<{ interactiveImages: any }>());
export const deleteInteractiveImagesSuccess = createAction("[InteractiveImages] Delete InteractiveImages Success", props<{ interactiveImagesId: any }>());
export const deleteInteractiveImagesFailure = createAction("[InteractiveImages] Delete InteractiveImages Failure", props<{ reason: string }>());

export const getInteractiveImages = createAction("[InteractiveImages] InteractiveImages get", props<{ interactiveImages: any }>());
export const getInteractiveImagesSuccess = createAction("[InteractiveImages] get InteractiveImages Success", props<{ interactiveImages: any }>());
export const getInteractiveImagesFailure = createAction("[InteractiveImages] get InteractiveImages Failure", props<{ reason: string }>());