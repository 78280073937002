/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CartoCases {
    category: string;
    createdAt: string;
    description: string;
    dislikes: number;
    id: string;
    imagePreviewLink: string;
    indexSignals: string;
    latencies: string;
    likes: number;
    signals: string
    title: string
    triangles:string;
    type: string
    userId: string
    vertex: string;
    views: number;
    vrcFolder: string;
}


