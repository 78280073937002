import { createAction, props } from "@ngrx/store";
import { Cardio3DCategoryControllerGetManyCardio3DCategoriesRequestParams } from "src/app/services/api/api/cardio3DCategory.serviceInterface";
import { Cardio3DCategory } from "src/app/services/api/model/cardio3DCategory";

export const loadManyCardio3DCategories = createAction("[Cardio 3D Category] Load Many categories", props<Cardio3DCategoryControllerGetManyCardio3DCategoriesRequestParams>());
export const loadManyCardio3DCategoriesSuccess = createAction("[Cardio 3D Category] Load Many cardio3D categories Success", props<{ manyCardio3DCategories: Array<Cardio3DCategory> }>());
export const loadManyCardio3DCategoriesFailure = createAction("[Cardio 3D Category] Load Many cardio3D categories Failure");

export const loadCardio3DCategory = createAction("[Cardio 3D Category] Load Cardio3D Category", props<{ cardio3DCategoryId: string }>());
export const loadCardio3DCategorySuccess = createAction("[Cardio 3D Category] Load Cardio 3D Category Success", props<{ cardio3DCategory: Cardio3DCategory }>());
export const loadCardio3DCategoryFailure = createAction("[Cardio 3D Category] Load Cardio 3D Category Failure");
