import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as Cardio3DActions from "./actions";
import { Cardio3DService } from "src/app/services/api";
import * as FromCardio3D from "../cardio3D/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class Cardio3DEffects {
  public loadManyCardio3D$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(Cardio3DActions.loadManyCardio3D),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.cardio3DService.cardio3DControllerGetManyCardio3D({ page, limit, filters });
          return Cardio3DActions.loadManyCardio3DSuccess({ manyCardio3D: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return Cardio3DActions.loadManyCardio3DFailure();
        }
      }),
    ),
  );

  public updateDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(Cardio3DActions.updateCardio3D),
      withLatestFrom(this.store.select(FromCardio3D.selectID)),
      mergeMap(async (action) => {
        const cardio3D = action[0].cardio3D; 
        try {
          const result: any = await this.cardio3DService.cardio3DControllerUpdateOne({
            cardio3D : cardio3D,
          });
          return Cardio3DActions.updateCardio3DSuccess({ cardio3D: result });
        } catch (error: any) {
          console.error(error);
          return Cardio3DActions.updateCardio3DFailure({ reason: error.message });
        }
      })
    )
  );

  public createDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(Cardio3DActions.createCardio3D),
      withLatestFrom(this.store.select(FromCardio3D.selectID)),
      mergeMap(async (action) => {
        const cardio3D = action[0].cardio3D; 
        try {
          const result: any = await this.cardio3DService.cardio3DControllerCreateCardio3D({
            cardio3D : cardio3D,
          });
          return Cardio3DActions.createCardio3DSuccess({ cardio3D: result });
        } catch (error: any) {
          console.error(error);
          return Cardio3DActions.createCardio3DFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteReakCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(Cardio3DActions.deleteCardio3D),
      withLatestFrom(this.store.select(FromCardio3D.selectID)),
      mergeMap(async (action) => {
        const cardio3D = action[0].cardio3D; 
        try {
          const result: any = await this.cardio3DService.cardio3DControllerDeleteCardio3D({
            cardio3D : cardio3D,
          });
          return Cardio3DActions.deleteCardio3DSuccess({ cardio3DId: result.id });
        } catch (error: any) {
          console.error(error);
          return Cardio3DActions.deleteCardio3DFailure({ reason: error.message });
        }
      })
    ),
  );

  public getDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(Cardio3DActions.getCardio3D),
      withLatestFrom(this.store.select(FromCardio3D.selectID)),
      mergeMap(async (action) => {
        const cardio3D = action[0].cardio3D; 
        try {
          const result: any = await this.cardio3DService.cardio3DControllerGetCardio3D({
            cardio3D : cardio3D,
          });
          return Cardio3DActions.getCardio3DSuccess({ cardio3D: result });
        } catch (error: any) {
          console.error(error);
          return Cardio3DActions.getCardio3DFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private cardio3DService: Cardio3DService, private store: Store<RootState>) {}
}
