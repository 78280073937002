import { Dicom } from "src/app/services/api";

export const persistedKeys = ["total","manyDicom"];

export interface ManyDicom {
  [id: string]: Dicom;
}

export interface DicomState {
  currentDicom: any;
  manyDicom: ManyDicom;
  manyDicomArray: Dicom[];
  loading: boolean;
  total: number;
  id:string;
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: DicomState = {
  currentDicom:{},
  manyDicom: {},
  manyDicomArray: [],
  loading: false,
  total: 0,
  id:"",
  likes: 0,
  dislikes: 0,
  views: 0
};
