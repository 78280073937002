import { createAction, props } from "@ngrx/store";
import { Development} from "src/app/services/api";
import { DevelopmentControllerGetManyDevelopmentRequestParams } from "src/app/services/api/api/development.serviceInterface";

export const loadManyDevelopment = createAction("[Development] Load Many Development", props<DevelopmentControllerGetManyDevelopmentRequestParams>());
export const loadManyDevelopmentSuccess = createAction("[Development] Load Many Development Success", props<{ manyDevelopment: any, filtered: boolean }>());
export const loadManyDevelopmentFailure = createAction("[Development] Load Many Development Failure");

export const loadDevelopment = createAction("[Development] Load Development", props<{ developmentId: string }>());
export const loadDevelopmentSuccess = createAction("[Development] Load Development Success", props<{ development: Development }>());
export const loadDevelopmentFailure = createAction("[Development] Load Development Failure");

export const updateDevelopment = createAction("[Development] Development Update", props<{ development: any }>());
export const updateDevelopmentSuccess = createAction("[Development] Update Development Success", props<{ development: any }>());
export const updateDevelopmentFailure = createAction("[Development] Update Development Failure", props<{ reason: string }>());

export const createDevelopment = createAction("[Development] Development Create", props<{ development: any }>());
export const createDevelopmentSuccess = createAction("[Development] Create Development Success", props<{ development: any }>());
export const createDevelopmentFailure = createAction("[Development] Create Development Failure", props<{ reason: string }>());

export const deleteDevelopment = createAction("[Development] Development Delete", props<{ development: any }>());
export const deleteDevelopmentSuccess = createAction("[Development] Delete Development Success", props<{ developmentId: any }>());
export const deleteDevelopmentFailure = createAction("[Development] Delete Development Failure", props<{ reason: string }>());

export const getDevelopment = createAction("[Development] Development get", props<{ development: any }>());
export const getDevelopmentSuccess = createAction("[Development] get Development Success", props<{ development: any }>());
export const getDevelopmentFailure = createAction("[Development] get Development Failure", props<{ reason: string }>());