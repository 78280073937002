import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "src/app/constants";
import { News } from "src/app/services/api";
import { RootState } from "..";
import { ManyNews } from "./state";

export const selectFeature = (state: RootState): ManyNews => state.news.manyNews;
export const selectID = (state: RootState): any => state.news.id;

export const selectAll = createSelector(selectFeature, (manyNews: ManyNews) => Object.values(manyNews).filter(Boolean));

export const selectPaged = createSelector(selectAll, (news: News[], page: number) =>
  news
    .sort((a: News, b: News) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyNews: News[], id: string) => manyNews.find(news => news.id === id));

export const selectLoading = (state: RootState): boolean => state.news.loading;

export const selectCurrentNew = (state: RootState): any => state.news.currentNew;

export const selectTotal = (state: RootState): number => {return state.news.total};

export const selectRelatedNews = (state: RootState): ManyNews => state.news.manyNews;

export const selectRelatedNewsArray = (state: RootState): News[] => state.news.manyNewsArray;

export const selectFilteredNews = (categories: string[]):
MemoizedSelector<RootState, News[], DefaultProjectorFn<News[]>> =>
  createSelector(selectRelatedNews, (news: ManyNews) =>{
    let val : News[] = Object.values(news); 
    let newsFiltered : News[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          newsFiltered.push(element);
        }
      });
    });   
    return newsFiltered;
  }  
);

export const selectFilteredNewsArray = (categories: string[]):
MemoizedSelector<RootState, News[], DefaultProjectorFn<News[]>> =>
  createSelector(selectRelatedNewsArray, (news: News[]) =>{
    let newsFiltered : News[] = [];        
    news.forEach(element => {
      categories.forEach(category=>{
        if(element.category==undefined){
          console.log(element)
        }
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          newsFiltered.push(element);
        }
      });
    });   
    return newsFiltered;
  }  
);


export const selectFilteredSearchNewsArray = (text: string): 
  MemoizedSelector<RootState, News[], DefaultProjectorFn<News[]>> =>
  createSelector(selectRelatedNewsArray, (news: News[]) =>{
    let newsFiltered : News[] = [];        
    news.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        newsFiltered.push(element);
      }
    });   
    return newsFiltered;
  }   
  );
