import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as TeamActions from "./actions";
import { Team } from "src/app/services/api";
import { TeamUser } from "src/app/services/api/model/teamUser";

export const teamReducer = createReducer(
  initialState,

  on(TeamActions.loadTeam, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(TeamActions.loadTeamFailure, state => ({
    ...state,
    loading: false,
  })),

  on(TeamActions.loadTeamSuccess, (state, { team }) => {
    let newSectors : Array<string> = [];
    const newSectorMembers: { [key: string]: Array<TeamUser> } = {};
    team.forEach(element => {
      let sectorArray = newSectorMembers[element.sector];
      if(sectorArray === undefined || sectorArray === null){
        sectorArray = [];
      }
      sectorArray.push(element);
      newSectorMembers[element.sector] = sectorArray;
      if(newSectors.indexOf(element.sector) == -1){
        newSectors.push(element.sector);
      }
    });
    return {
      ...state,
      team: team,
      sectors: newSectors,
      sectorMembers: newSectorMembers,
      loading: false,
    };
  }),
);
