import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as DicomActions from "./actions";
import { Dicom } from "src/app/services/api";

export const dicomReducer = createReducer(
  initialState,

  on(DicomActions.loadManyDicom, DicomActions.loadDicom, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(DicomActions.loadManyDicomFailure, DicomActions.loadDicomFailure, state => ({
    ...state,
    loading: false,
  })),

  on(DicomActions.loadManyDicomSuccess, (state, { manyDicom, filtered }) => {
    const newDicom: { [key: string]: Dicom } = {};
    // Check query petition result
    if(manyDicom.dicom != undefined && manyDicom.dicom.length > 0){
    
      if(!filtered){
        let dicomArray = manyDicom.dicom[0];
        Object.keys(dicomArray).map((key: string) => {

          newDicom[key!] = dicomArray[key];
        });
      }else{
        manyDicom.dicom.forEach((dicom: Dicom) => {
          newDicom[dicom.id!] = dicom;
        });
      }
      let lastTotal = 0;
      if(manyDicom.isFiltered){
        if(manyDicom.dicom != undefined && manyDicom.dicom.length > 0){
          lastTotal = Object.keys(newDicom).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyDicom).forEach(element => {
        if(newDicom[element] == undefined){
          newDicom[element] = state.manyDicom[element];
        }
      });
      let manyDicomArrayAux : Dicom[] = [...state.manyDicomArray];
      Object.values(newDicom).forEach(dicom => {
        let inserted : boolean = false;
        manyDicomArrayAux.forEach(element => {
          if(element.id == dicom.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyDicomArrayAux = [...manyDicomArrayAux, dicom]
        }
      });
      return {
        ...state,
        total: manyDicomArrayAux.length,
        manyDicom: {
          ...newDicom
        },
        manyDicomArray: manyDicomArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(DicomActions.loadDicomSuccess, (state, { dicom }) => {
    return {
      ...state,
      manyDicom: {
        ...state.manyDicom,
        [dicom.id!]: dicom,
      },
      loading: false,
    };
  }),

  on(DicomActions.getDicomSuccess, (state, { dicom }) => {
    return {
      ...state,
      currentDicom: dicom[0],
      loading: false,
    }
  }),

  on(DicomActions.getDicomFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(DicomActions.updateDicomSuccess, (state, { dicom}) => {
    return {
      ...state,
      loading: false,
      category: dicom.category,
      description: dicom.description,
      duration: dicom.duration,
      id: dicom.id,
      imagePreviewLink: dicom.imagePreviewLink,
      link: dicom.link,
      title: dicom.title,
      type: dicom.type,
      userId: dicom.userId,
    }
  }),

  on(DicomActions.updateDicomFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(DicomActions.deleteDicomSuccess, (state, { dicomId }) => {
    const manyDicom = Object.values(state.manyDicom).filter(developmen => developmen.id !== dicomId);
    const newDicom: { [key: string]: Dicom } = {};
    manyDicom.forEach((dicom: Dicom) => (newDicom[dicom.id!] = dicom));
    return {
      ...state, 
      manyDicomArray: manyDicom,
      manyDicom: newDicom,
      loading: false,
    };  
  }),

  on(DicomActions.deleteDicomFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
