import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as DevelopmentActions from "./actions";
import { DevelopmentService } from "src/app/services/api";
import * as FromDevelopment from "../development/selectors";
import { RootState } from "..";
import { Store } from "@ngrx/store";

@Injectable()
export class DevelopmentEffects {
  public loadManyDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentActions.loadManyDevelopment),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.developmentService.developmentControllerGetManyDevelopment({page, limit, filters });
          return DevelopmentActions.loadManyDevelopmentSuccess({ manyDevelopment: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return DevelopmentActions.loadManyDevelopmentFailure();
        }
      }),
    ),
  );

  public updateDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentActions.updateDevelopment),
      withLatestFrom(this.store.select(FromDevelopment.selectID)),
      mergeMap(async (action) => {
        const development = action[0].development; 
        try {
          const result: any = await this.developmentService.developmentControllerUpdateOne({
            development : development,
          });
          return DevelopmentActions.updateDevelopmentSuccess({ development: result });
        } catch (error: any) {
          console.error(error);
          return DevelopmentActions.updateDevelopmentFailure({ reason: error.message });
        }
      })
    )
  );

  public createDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentActions.createDevelopment),
      withLatestFrom(this.store.select(FromDevelopment.selectID)),
      mergeMap(async (action) => {
        const development = action[0].development; 
        try {
          const result: any = await this.developmentService.developmentControllerCreateDevelopment({
            development : development,
          });
          return DevelopmentActions.createDevelopmentSuccess({ development: result });
        } catch (error: any) {
          console.error(error);
          return DevelopmentActions.createDevelopmentFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteReakCases$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentActions.deleteDevelopment),
      withLatestFrom(this.store.select(FromDevelopment.selectID)),
      mergeMap(async (action) => {
        const development = action[0].development; 
        try {
          const result: any = await this.developmentService.developmentControllerDeleteDevelopment({
            development : development,
          });
          return DevelopmentActions.deleteDevelopmentSuccess({ developmentId: result.id });
        } catch (error: any) {
          console.error(error);
          return DevelopmentActions.deleteDevelopmentFailure({ reason: error.message });
        }
      })
    ),
  );

  public getDevelopment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentActions.getDevelopment),
      withLatestFrom(this.store.select(FromDevelopment.selectID)),
      mergeMap(async (action) => {
        const development = action[0].development; 
        try {
          const result: any = await this.developmentService.developmentControllerGetDevelopment({
            development : development,
          });
          return DevelopmentActions.getDevelopmentSuccess({ development: result });
        } catch (error: any) {
          console.error(error);
          return DevelopmentActions.getDevelopmentFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private developmentService: DevelopmentService, private store: Store<RootState>) {}
}
