import { createAction, props } from "@ngrx/store";
import { RealCases} from "src/app/services/api";
import { RealCasesControllerGetManyRealCasesRequestParams } from "src/app/services/api/api/realCases.serviceInterface";

export const loadManyRealCases = createAction("[RealCases] Load Many RealCases", props<RealCasesControllerGetManyRealCasesRequestParams>());
export const loadManyRealCasesSuccess = createAction("[RealCases] Load Many RealCases Success", props<{ manyRealCases: any, filtered: boolean }>());
export const loadManyRealCasesFailure = createAction("[RealCases] Load Many RealCases Failure");

export const loadRealCases = createAction("[RealCases] Load RealCases", props<{ realCasesId: string }>());
export const loadRealCasesSuccess = createAction("[RealCases] Load RealCases Success", props<{ realCases: RealCases }>());
export const loadRealCasesFailure = createAction("[RealCases] Load RealCases Failure");

export const updateRealCases = createAction("[RealCases] Real Cases Update", props<{ realCase: any }>());
export const updateRealCasesSuccess = createAction("[RealCases] Update Real Cases Success", props<{ realCase: any }>());
export const updateRealCasesFailure = createAction("[RealCases] Update Real Cases Failure", props<{ reason: string }>());

export const createRealCases = createAction("[RealCases] Real Cases Create", props<{ realCase: any }>());
export const createRealCasesSuccess = createAction("[RealCases] Create Real Cases Success", props<{ realCase: any }>());
export const createRealCasesFailure = createAction("[RealCases] Create Real Cases Failure", props<{ reason: string }>());

export const deleteRealCases = createAction("[RealCases] Real Cases Delete", props<{ realCase: any }>());
export const deleteRealCasesSuccess = createAction("[RealCases] Delete Real Cases Success", props<{ realCaseId: any }>());
export const deleteRealCasesFailure = createAction("[RealCases] Delete Real Cases Failure", props<{ reason: string }>());

export const getRealCases = createAction("[RealCases] Real Cases get", props<{ realCase: any }>());
export const getRealCasesSuccess = createAction("[RealCases] get Real Cases Success", props<{ realCase: any }>());
export const getRealCasesFailure = createAction("[RealCases] get Real Cases Failure", props<{ reason: string }>());