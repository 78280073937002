<html>
    
    <div class="footer">
        <!-- <div class="footer__container"> -->
            <div class="footer__left">
                <img class="footer__left__image" src="assets/logo_Vertical_SPK_TM_monochrome.png" alt="Spika Tech logo">
                <img class="footer__left__image" src="assets/CarciacAnatomyAtlasWhite.png" alt="Atlas logo">
            </div>
            <div class="footer__center">
                <span class="footer__center__text__selectable" [innerHTML]="'FOOTER.PRIVACYPOLICY' | translate" 
                    (click)="goToPrivacyPolicySection()"></span>
                <span class="footer__center__separator" [innerHTML]="'&nbsp;|&nbsp;'"></span>
                <span class="footer__center__text__selectable" id="footer__center__text__selectable" [innerHTML]="'FOOTER.COOKIESPOLICY' | translate"
                    (click)="openCookiesPopUp()"></span>
                <span class="footer__center__separator" [innerHTML]="'&nbsp;|&nbsp;'"></span>
                <span class="footer__center__text__selectable" [innerHTML]="'FOOTER.TERMS' | translate"
                    (click)="goToLegalInformationSection()"></span>         
            </div>  
            <div  class="footer__right">
                <i id="footerIcon" class="fa fa-twitter footer__right__icon__twitter" (click)="goToPage('TWITTER')"></i>
                <i id="footerIcon" class="fa fa-instagram footer__right__icon__instagram" (click)="goToPage('INSTAGRAM')"></i>
                <i id="footerIcon" class="fa fa-linkedin footer__right__icon__linkedin" (click)="goToPage('LINKEDIN')"></i>
                <i id="footerIcon" class="fa fa-facebook footer__right__icon__facebook" (click)="goToPage('FACEBOOK')"></i>
                <i id="footerIcon" class="fa fa-youtube footer__right__icon__youtube" (click)="goToPage('YOUTUBE')"></i>                    
            </div>
        <!-- </div>
       
        <img class="footer__img" src="assets/Slide1.jpg"> -->

    </div>


    <div id="cookiesPopUp" class="cookies">    
        <div class="cookies__all">
            
            <div class="cookies__all__XButton">
                <button class="cookies__all__XButton__button" (click)="closeCookiesPopUp()">&times;</button>
            </div> 
            <div class="cookies__all__content"> 
                    <span class="cookies__all__content__title" [innerHTML]="'COOKIES.USE_OF_COOKIES_NAME' | translate"></span>
                    <span class="cookies__all__content__text" [innerHTML]="'COOKIES.USE_OF_COOKIES' | translate"></span>
                    <span class="cookies__all__content__title" [innerHTML]="'COOKIES.COOKIE_PREFERENCES' | translate"></span>
                    <span class="cookies__all__content__subtitle" [innerHTML]="'COOKIES.ESSENTIAL_COOKIES_NAME' | translate"></span>
                    <span class="cookies__all__content__textRightSide" [innerHTML]="'COOKIES.ALWAYS_ACTIVATED' | translate"></span>
                    <span class="cookies__all__content__text" [innerHTML]="'COOKIES.ESSENTIAL_COOKIES' | translate"></span>
                    <span class="cookies__all__content__subtitle" [innerHTML]="'COOKIES.FUNCIONAL_COOKIES_NAME' | translate"></span>
                    <span class="cookies__all__content__text" [innerHTML]="'COOKIES.FUNCIONAL_COOKIES' | translate"></span>
                    <!-- Funcional cookie button-->
                    <label class="switch">
                        <input type="checkbox" checked>
                        <span class="slider round" (click)="funcionalCookie()"></span>
                    </label>
                    <span class="cookies__all__content__subtitle" [innerHTML]="'COOKIES.PERFORMANCE_COOKIES_NAME' | translate"></span>
                    <span class="cookies__all__content__text" [innerHTML]="'COOKIES.PERFORMANCE_COOKIES' | translate"></span>
                    <!-- Performance cookie button-->
                    <label class="switch">
                        <input type="checkbox" checked>
                        <span class="slider round" (click)="performanceCookie()"></span>
                    </label>
                    <span class="cookies__all__content__subtitle" [innerHTML]="'COOKIES.MARKETING_COOKIES_NAME' | translate"></span>
                    <span class="cookies__all__content__text" [innerHTML]="'COOKIES.MARKETING_COOKIES' | translate"></span>
                    <!-- Marketing cookie button-->
                    <label class="switch">
                        <input type="checkbox" id="marketingCookieSwitch" checked>
                        <span class="slider round" (click)="marketingCookie()"></span>
                    </label>
                <div class="cookies__all__content__buttonZone">
                    <button class="cookies__all__content__buttonZone__button" id="cookiesConfirmButton" [innerHTML]="'COOKIES.CONFIRM' | translate" (click)="closeCookiesPopUp()"></button>
                </div>
            </div>
        </div>

    </div>
</html>