/* eslint-disable @typescript-eslint/explicit-function-return-type,@typescript-eslint/ban-types */
import { Action, ActionReducer, INIT } from "@ngrx/store";
import { initialRootState } from "..";
import * as UserActions from "./actions";

export const logoutMetaReducer =
  <State extends {}>(reducer: ActionReducer<State>) =>
    (state: State, action: Action) =>
      action.type === UserActions.logout.type
        ? reducer(initialRootState as any, { type: INIT })
        : reducer(state, action);
