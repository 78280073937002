import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as CartoCasesActions from "./actions";
import { CartoCases } from "src/app/services/api";

export const cartoCasesReducer = createReducer(
  initialState,

  on(CartoCasesActions.loadManyCartoCases, CartoCasesActions.loadCartoCases, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(CartoCasesActions.loadManyCartoCasesFailure, CartoCasesActions.loadCartoCasesFailure, state => ({
    ...state,
    loading: false,
  })),

  on(CartoCasesActions.loadManyCartoCasesSuccess, (state, { manyCartoCases, filtered }) => {
    const newCartoCases: { [key: string]: CartoCases } = {};
    // Check query petition result
    if(manyCartoCases.cartocases != undefined && manyCartoCases.cartocases.length > 0){
      
      if(!filtered){
        let cartoCasesArray = manyCartoCases.cartocases[0];
        Object.keys(cartoCasesArray).map((key: string) => {

          newCartoCases[key!] = cartoCasesArray[key];
        });
      }else{
        manyCartoCases.cartocases.forEach((cartoCases: CartoCases) => {
          newCartoCases[cartoCases.id!] = cartoCases;
        });
      }

      let lastTotal = 0;
      if(manyCartoCases.isFiltered){
        if(manyCartoCases.cartoCases != undefined && manyCartoCases.cartoCases.length > 0){
          lastTotal = Object.keys(newCartoCases).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyCartoCases).forEach(element => {
        if(newCartoCases[element] == undefined){
          newCartoCases[element] = state.manyCartoCases[element];
        }
      });
      let manyCartoCasesArrayAux : CartoCases[] = [...state.manyCartoCasesArray];
      Object.values(newCartoCases).forEach(cartoCases => {
        let inserted : boolean = false;
        manyCartoCasesArrayAux.forEach(element => {
          if(element.id == cartoCases.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyCartoCasesArrayAux = [...manyCartoCasesArrayAux, cartoCases]
        }
      });
      return {
        ...state,
        total: manyCartoCasesArrayAux.length,
        manyCartoCases: {
          ...newCartoCases
        },
        manyCartoCasesArray: manyCartoCasesArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(CartoCasesActions.loadCartoCasesSuccess, (state, { cartoCases }) => {
    return {
      ...state,
      manyCartoCases: {
        ...state.manyCartoCases,
        [cartoCases.id!]: cartoCases,
      },
      loading: false,
    };
  }),

  on(CartoCasesActions.getCartoCasesSuccess, (state, { cartoCase }) => {
    return {
      ...state,
      currentCartoCase: cartoCase[0],
      loading: false,
    }
  }),

  on(CartoCasesActions.getCartoCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(CartoCasesActions.updateCartoCasesSuccess, (state, { cartoCase}) => {
    return {
      ...state,
      loading: false,
      category: cartoCase.category,
      description: cartoCase.description,
      duration: cartoCase.duration,
      id: cartoCase.id,
      imagePreviewLink: cartoCase.imagePreviewLink,
      link: cartoCase.link,
      title: cartoCase.title,
      type: cartoCase.type,
      userId: cartoCase.userId,
      createdAt: cartoCase.createdAt,
      indexSignals: cartoCase.indexSignals,
      latencies: cartoCase.latencies,
      signals: cartoCase.signals,
      triangles: cartoCase.triangles,
      vertex: cartoCase.vertex,
      dislikes: cartoCase.actualDislikes,
      likes: cartoCase.actualLikes,
      views: cartoCase.actualViews,
      vrcFolder: cartoCase.vrcFolder
    }
  }),

  on(CartoCasesActions.updateCartoCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(CartoCasesActions.deleteCartoCasesSuccess, (state, { cartoCaseId }) => {
    const manyCartoCases = Object.values(state.manyCartoCases).filter(interactiveImages => interactiveImages.id !== cartoCaseId);
    const newCartoCases: { [key: string]: CartoCases } = {};
    manyCartoCases.forEach((cartoCases: CartoCases) => (newCartoCases[cartoCases.id!] = cartoCases));
    return {
      ...state, 
      manyCartoCasesArray: manyCartoCases,
      manyCartoCases: newCartoCases,
      loading: false,
    };  
  }),

  on(CartoCasesActions.deleteCartoCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

);
