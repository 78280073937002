import { DevelopmentCategory } from "src/app/services/api/model/developmentCategory";

export const persistedKeys = ["total","manyDevelopmentCategories"];

export interface ManyDevelopmentCategories {
  [title: string]: DevelopmentCategory;
}

export interface DevelopmentCategoryState {
  manyDevelopmentCategories: ManyDevelopmentCategories;
  loading: boolean;
  total: number;
}

export const initialState: DevelopmentCategoryState = {
  manyDevelopmentCategories: {},
  loading: false,
  total: 0,
};
