import { createAction, props } from "@ngrx/store";
import { ManyNews, News, NewsControllerGetManyNewsRequestParams } from "src/app/services/api";

export const loadManyNews = createAction("[News] Load Many News", props<NewsControllerGetManyNewsRequestParams>());
export const loadManyNewsSuccess = createAction("[News] Load Many News Success", props<{ manyNews: any, filtered: any }>());
export const loadManyNewsFailure = createAction("[News] Load Many News Failure");

export const loadNews = createAction("[News] Load News", props<{ newsId: string }>());
export const loadNewsSuccess = createAction("[News] Load News Success", props<{ news: News }>());
export const loadNewsFailure = createAction("[News] Load News Failure");

export const updateNews = createAction("[News] News Update", props<{ news: any }>());
export const updateNewsSuccess = createAction("[News] Update News Success", props<{ news: any }>());
export const updateNewsFailure = createAction("[News] Update News Failure", props<{ reason: string }>());

export const createNews = createAction("[News] News Create", props<{ news: any }>());
export const createNewsSuccess = createAction("[News] Create News Success", props<{ news: any }>());
export const createNewsFailure = createAction("[News] Create News Failure", props<{ reason: string }>());

export const deleteNews = createAction("[News] News Delete", props<{ news: any }>());
export const deleteNewsSuccess = createAction("[News] Delete News Success", props<{ newsId: any }>());
export const deleteNewsFailure = createAction("[News] Delete News Failure", props<{ reason: string }>());

export const getNews = createAction("[News] News get", props<{ news: any }>());
export const getNewsSuccess = createAction("[News] get News Success", props<{ news: any }>());
export const getNewsFailure = createAction("[News] get News Failure", props<{ reason: string }>());