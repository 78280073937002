import { createAction, props } from "@ngrx/store";
import { CategoryControllerGetManyCategoriesRequestParams } from "src/app/services/api/api/category.serviceInterface";
import { Category } from "src/app/services/api/model/category";

export const loadManyCategories = createAction("[Category] Load Many categories", props<CategoryControllerGetManyCategoriesRequestParams>());
export const loadManyCategoriesSuccess = createAction("[Category] Load Many categories Success", props<{ manyCategories: Array<Category> }>());
export const loadManyCategoriesFailure = createAction("[Category] Load Many categories Failure");

export const loadCategory = createAction("[Category] Load Category", props<{ categoryId: string }>());
export const loadCategorySuccess = createAction("[Category] Load Category Success", props<{ category: Category }>());
export const loadCategoryFailure = createAction("[Category] Load Category Failure");
