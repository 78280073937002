/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InteractiveImages {
    category: any; 
    createdAt: string;
    description: string;
    field: any;    
    descriptionField: any;
    height: number;
    name: string;
    title: string;
    width: number;
    id: string;
    image: string;
    type: string;
    likes: number;
    dislikes: number;
    views: number;
    userId: string;
}

