import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "src/app/constants";
import { Development } from "src/app/services/api";
import { RootState } from "..";
import { ManyDevelopment } from "./state";

export const selectFeature = (state: RootState): ManyDevelopment => state.development.manyDevelopment;

export const selectID = (state: RootState): any => state.development.id;

export const selectCurrentDevelopment = (state: RootState): any => state.development.currentDevelopment;

export const selectAll = createSelector(selectFeature, (manyDevelopment: ManyDevelopment) => Object.values(manyDevelopment).filter(Boolean));

export const selectPaged = createSelector(selectAll, (development: Development[], page: number) =>
  development
    .sort((a: Development, b: Development) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyDevelopment: Development[], id: string) => manyDevelopment.find(development => development.id === id));

export const selectLoading = (state: RootState): boolean => state.development.loading;

export const selectTotal = (state: RootState): number => state.development.total;

export const selectRelatedDevelopment = (state: RootState): ManyDevelopment => state.development.manyDevelopment;

export const selectRelatedDevelopmentArray = (state: RootState): Development[] => state.development.manyDevelopmentArray;

export const selectArrayFilteredDevelopment = (state: RootState): Development[] => state.development.manyDevelopmentArray;

export const selectFilteredDevelopment = (categories: string[]):
MemoizedSelector<RootState, Development[], DefaultProjectorFn<Development[]>> =>
  createSelector(selectRelatedDevelopment, (news: ManyDevelopment) =>{
    let val : Development[] = Object.values(news); 
    let developmentFiltered : Development[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          developmentFiltered.push(element);
        }
      });
    });   
    return developmentFiltered;
  }  
);

export const selectFilteredDevelopmentArray = (categories: string[]):
MemoizedSelector<RootState, Development[], DefaultProjectorFn<Development[]>> =>
  createSelector(selectRelatedDevelopmentArray, (development: Development[]) =>{
    let developmentFiltered : Development[] = [];        
    development.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          developmentFiltered.push(element);
        }
      });
    }); 
    return developmentFiltered;
  }  
);

export const selectFilteredSearchDevelopmentArray = (text: string): 
  MemoizedSelector<RootState, Development[], DefaultProjectorFn<Development[]>> =>
  createSelector(selectRelatedDevelopmentArray, (development: Development[]) =>{
    let developmentFiltered : Development[] = [];        
    development.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        developmentFiltered.push(element);
      }
    });   
    return developmentFiltered;
  }   
);