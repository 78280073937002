import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { VideoIntroComponent } from "./videoIntro.component";

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [VideoIntroComponent],
  exports: [VideoIntroComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VideoIntroComponentModule {}
