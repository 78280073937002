import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as RealCasesActions from "./actions";
import { RealCases } from "src/app/services/api";

export const realCasesReducer = createReducer(
  initialState,

  on(RealCasesActions.loadManyRealCases, RealCasesActions.loadRealCases, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(RealCasesActions.loadManyRealCasesFailure, RealCasesActions.loadRealCasesFailure, state => ({
    ...state,
    loading: false,
  })),

  on(RealCasesActions.loadManyRealCasesSuccess, (state, { manyRealCases, filtered }) => {
    const newRealCases: { [key: string]: RealCases } = {};
    // Check query petition result
    if(manyRealCases.realcases != undefined && manyRealCases.realcases.length > 0){
      
      if(!filtered){
        let realCasesArray = manyRealCases.realcases[0];
        Object.keys(realCasesArray).map((key: string) => {

          newRealCases[key!] = realCasesArray[key];
        });
      }else{
        manyRealCases.realcases.forEach((realCases: RealCases) => {
          newRealCases[realCases.id!] = realCases;
        });
      }

      let lastTotal = 0;
      if(manyRealCases.isFiltered){
        if(manyRealCases.realCases != undefined && manyRealCases.realCases.length > 0){
          lastTotal = Object.keys(newRealCases).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyRealCases).forEach(element => {
        if(newRealCases[element] == undefined){
          newRealCases[element] = state.manyRealCases[element];
        }
      });
      let manyRealCasesArrayAux : RealCases[] = [...state.manyRealCasesArray];
      Object.values(newRealCases).forEach(realCases => {
        let inserted : boolean = false;
        manyRealCasesArrayAux.forEach(element => {
          if(element.id == realCases.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyRealCasesArrayAux = [...manyRealCasesArrayAux, realCases]
        }
      });
      return {
        ...state,
        total: manyRealCasesArrayAux.length,
        manyRealCases: {
          ...newRealCases
        },
        manyRealCasesArray: manyRealCasesArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(RealCasesActions.loadRealCasesSuccess, (state, { realCases }) => {
    return {
      ...state,
      manyRealCases: {
        ...state.manyRealCases,
        [realCases.id!]: realCases,
      },
      loading: false,
    };
  }),

  on(RealCasesActions.getRealCasesSuccess, (state, { realCase }) => {
    return {
      ...state,
      currentRealCase: realCase[0],
      loading: false,
    }
  }),

  on(RealCasesActions.getRealCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(RealCasesActions.updateRealCasesSuccess, (state, { realCase}) => {
    return {
      ...state,
      loading: false,
      category: realCase.category,
      description: realCase.description,
      duration: realCase.duration,
      id: realCase.id,
      imagePreviewLink: realCase.imagePreviewLink,
      link: realCase.link,
      title: realCase.title,
      type: realCase.type,
      userId: realCase.userId,
    }
  }),

  on(RealCasesActions.updateRealCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(RealCasesActions.deleteRealCasesSuccess, (state, { realCaseId }) => {
    const manyRealCases = Object.values(state.manyRealCases).filter(interactiveImages => interactiveImages.id !== realCaseId);
    const newRealCases: { [key: string]: RealCases } = {};
    manyRealCases.forEach((realCases: RealCases) => (newRealCases[realCases.id!] = realCases));
    return {
      ...state, 
      manyRealCasesArray: manyRealCases,
      manyRealCases: newRealCases,
      loading: false,
    };  
  }),

  on(RealCasesActions.deleteRealCasesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

);
