/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { PageRoutes } from "src/app/enums";
import { AuthService } from "src/app/services/api";
import * as UserActions from "src/app/store/user/actions";
import { RootState } from "..";
import * as FromUser from "../user/selectors";
import { UserService } from "src/app/services/api/api/user.service";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class UserEffects {
  public login$ = createEffect(() =>
  this.actions$.pipe(
    ofType(UserActions.login),
    mergeMap(async ({ loginBody }) => {
      try {
        const user : any = await this.authService.authControllerLogin({ loginBody });
        return UserActions.loginSuccess({ user });
      } catch (error) {
        console.error(error)
        return UserActions.loginFailure();
      }
    }),
  ),
);

  public logout$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => {
          localStorage.clear();
          this.router.navigate([PageRoutes.login]);
        }),
      ),
    { dispatch: false },
  );

  public loginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        switchMap(({ user }) => {
          if (user.smsVerification) {
            return this.router.navigate(['/smsverification']);
          } else {
            return this.router.navigate(['']); 
          }
        }),
      ),
    { dispatch: false },
  );

  public updateUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      withLatestFrom(this.store.select(FromUser.selectUserID)),
      mergeMap(async (action) => {
        const update = action[0].update; // Extract user from the action parameter
        const user = action[0].user; // Extract user from the action parameter
        const newUserId = action[0].newUserId; // Extract user from the action parameter
        try {
          const result: any = await this.userService.userControllerUpdateOne({
            user: { ...user },
            update: update,
            userId: user.id!,
            newUserId: newUserId,
            role: user.webRole,
          });
          return UserActions.updateUserSuccess({ user: result });
        } catch (error: any) {
          console.error(error);
          return UserActions.updateUserFailure({ reason: error.message });
        }
      })
    )
  );
  
  public registerUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerUser),
      mergeMap(( user ) =>
        this.userService.userControllerRegisterUser( {user:user }).pipe(
            map(user => UserActions.registerUserSuccess({ user })),
            catchError(() => of(UserActions.registerUserFailure())),
          ),
      ),
    ),
  );

  public registerUserSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.registerUserSuccess),
        tap(() => this.router.navigate(["login"])),
      ),
    { dispatch: false },
  );
  
  public deleteUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      withLatestFrom(this.store.select(FromUser.selectUserID)),
      mergeMap(async (action) => {
        const user = action[0].user; 
        const uid = action[0].uid; 
        try {
          const result: any = await this.userService.userControllerDeleteUser({
            user : user,
            uid: uid
          });
          return UserActions.deleteUserSuccess({ user: result });
        } catch (error: any) {
          console.error(error);
          return UserActions.deleteUserFailure({ reason: error.message });
        }
      })
    ),
  );

  constructor(private actions$: Actions, private authService: AuthService, private userService: UserService, private router: Router, private store: Store<RootState>) {}
}