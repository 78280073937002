import { EcgCategory } from "src/app/services/api/model/ecgCategory";

export const persistedKeys = ["total","manyEcgCategories"];

export interface ManyEcgCategories {
  [title: string]: EcgCategory;
}

export interface EcgCategoryState {
  manyEcgCategories: ManyEcgCategories;
  loading: boolean;
  total: number;
}

export const initialState: EcgCategoryState = {
  manyEcgCategories: {},
  loading: false,
  total: 0,
};
