import { ActionReducerMap } from "@ngrx/store";
import { NewsEffects } from "./news/effects";
import { newsReducer } from "./news/reducer";
import { NewsState, initialState as news } from "./news/state";

import { EcgCategoryState, initialState as ecgCategory } from "./ecgCategory/state";
import { ecgCategoryReducer } from "./ecgCategory/reducer";
import { EcgCategoryEffects } from "./ecgCategory/effects";
import { EcgEffects } from "./ecg/effects";
import { ecgReducer } from "./ecg/reducer";
import { EcgState, initialState as ecg } from "./ecg/state";

import { InteractiveImagesEffects } from "./interactiveImages/effects";
import { interactiveImagesReducer } from "./interactiveImages/reducer";
import { InteractiveImagesState, initialState as interactiveImages } from "./interactiveImages/state";

import { UserEffects } from "./user/effects";
import { userReducer } from "./user/reducer";
import { UserState, initialState as user } from "./user/state";
import { CategoryState, initialState as category } from "./category/state";
import { categoryReducer } from "./category/reducer";
import { CategoryEffects } from "./category/effects";
import { ImageCategoryState, initialState as imageCategory } from "./imageCategory/state";
import { imageCategoryReducer } from "./imageCategory/reducer";
import { ImageCategoryEffects } from "./imageCategory/effects";

import { DevelopmentCategoryState, initialState as developmentCategory } from "./developmentCategory/state";
import { developmentCategoryReducer } from "./developmentCategory/reducer";
import { DevelopmentCategoryEffects } from "./developmentCategory/effects";
import { developmentReducer } from "./development/reducer";
import { DevelopmentState, initialState as development } from "./development/state";
import { DevelopmentEffects } from "./development/effects";

import { ScienceInsightsCategoryState, initialState as scienceInsightsCategory } from "./scienceInsightsCategory/state";
import { scienceInsightsCategoryReducer } from "./scienceInsightsCategory/reducer";
import { ScienceInsightsCategoryEffects } from "./scienceInsightsCategory/effects";
import { scienceInsightsReducer } from "./scienceInsights/reducer";
import { ScienceInsightsState, initialState as scienceInsights } from "./scienceInsights/state";
import { ScienceInsightsEffects } from "./scienceInsights/effects";

import { RealCasesCategoryState, initialState as realCasesCategory } from "./realCasesCategory/state";
import { realCasesCategoryReducer } from "./realCasesCategory/reducer";
import { RealCasesCategoryEffects } from "./realCasesCategory/effects";
import { realCasesReducer } from "./realCases/reducer";
import { RealCasesState, initialState as realCases } from "./realCases/state";
import { RealCasesEffects } from "./realCases/effects";

import { Cardio3DCategoryState, initialState as cardio3DCategory } from "./cardio3DCategory/state";
import { cardio3DCategoryReducer } from "./cardio3DCategory/reducer";
import { Cardio3DCategoryEffects } from "./cardio3DCategory/effects";
import { cardio3DReducer } from "./cardio3D/reducer";
import { Cardio3DState, initialState as cardio3D } from "./cardio3D/state";
import { Cardio3DEffects } from "./cardio3D/effects";

import { teamReducer } from "./team/reducer";
import { TeamState, initialState as team } from "./team/state";
import { TeamEffects } from "./team/effects";

import { SoftwareState, initialState as software } from "./software/state";
import { softwareReducer } from "./software/reducer";
import { SoftwareEffects } from "./software/effects";

import { cartoCasesReducer } from "./cartoCases/reducer";
import { CartoCasesState, initialState as cartoCases } from "./cartoCases/state";
import { CartoCasesEffects } from "./cartoCases/effects";

import { dicomReducer } from "./dicom/reducer";
import { DicomState, initialState as dicom } from "./dicom/state";
import { DicomEffects } from "./dicom/effects";

export interface RootState {
  interactiveImages: InteractiveImagesState;
  development: DevelopmentState;
  dicom: DicomState;
  realCases: RealCasesState;
  cartoCases: CartoCasesState;
  cardio3D: Cardio3DState;
  user: UserState;
  news: NewsState;
  ecg: EcgState;
  team: TeamState;
  scienceInsights: ScienceInsightsState;
  category: CategoryState;
  ecgCategory: EcgCategoryState;
  imageCategory: ImageCategoryState;
  developmentCategory: DevelopmentCategoryState;
  realCasesCategory: RealCasesCategoryState;
  cardio3DCategory: Cardio3DCategoryState;
  scienceInsightsCategory: ScienceInsightsCategoryState;
  software: SoftwareState;
}

export const reducers: ActionReducerMap<RootState> = {
  user: userReducer,
  news: newsReducer,
  ecg: ecgReducer,
  team: teamReducer,
  development: developmentReducer,
  dicom: dicomReducer,
  realCases: realCasesReducer,
  cartoCases: cartoCasesReducer,
  cardio3D: cardio3DReducer,
  category: categoryReducer,
  ecgCategory: ecgCategoryReducer,
  imageCategory: imageCategoryReducer,
  interactiveImages: interactiveImagesReducer,
  developmentCategory: developmentCategoryReducer,
  realCasesCategory: realCasesCategoryReducer,
  cardio3DCategory: cardio3DCategoryReducer,
  scienceInsights: scienceInsightsReducer,
  scienceInsightsCategory: scienceInsightsCategoryReducer,
  software: softwareReducer,
};

export const initialRootState: RootState = {
  team,
  user,
  news,
  ecg,
  development,
  dicom,
  realCases,
  cartoCases,
  cardio3D,
  category,
  scienceInsights,
  imageCategory,
  interactiveImages,
  developmentCategory,
  scienceInsightsCategory,
  realCasesCategory,
  ecgCategory,
  cardio3DCategory,
  software,
};

export const rootEffects = [
  UserEffects, NewsEffects, CategoryEffects, InteractiveImagesEffects, ImageCategoryEffects, 
  DevelopmentEffects, DevelopmentCategoryEffects, RealCasesEffects, RealCasesCategoryEffects,
  Cardio3DCategoryEffects, Cardio3DEffects, TeamEffects, ScienceInsightsEffects, 
  ScienceInsightsCategoryEffects, EcgEffects, EcgCategoryEffects, SoftwareEffects, CartoCasesEffects, DicomEffects
];
