import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as TeamActions from "./actions";
import { TeamService } from "src/app/services/api/api/team.service";

@Injectable()
export class TeamEffects {

  public loadTeam$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(TeamActions.loadTeam),
      mergeMap(async ({  }) => {
        try {
          const result : any = await this.teamService.teamControllerGetTeam({}); 
          return TeamActions.loadTeamSuccess({ team: result });
        } catch (error) {
          console.error(error)
          return TeamActions.loadTeamFailure();
        }
      }),
    ),
  );

  constructor(private actions$: Actions, private teamService: TeamService) {}
}
