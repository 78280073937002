import { CartoCases } from "src/app/services/api";

export const persistedKeys = ["total","manyCartoCases"];

export interface ManyCartoCases {
  [id: string]: CartoCases;
}

export interface CartoCasesState {
  currentCartoCase: any;
  manyCartoCases: ManyCartoCases;
  manyCartoCasesArray: CartoCases[];
  loading: boolean;
  total: number;
  id: string;
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: CartoCasesState = {
  currentCartoCase : {},
  manyCartoCases: {},
  manyCartoCasesArray: [],
  loading: false,
  total: 0,
  id: "",
  likes: 0,
  dislikes: 0,
  views: 0
};
