/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageRoutes } from "./enums";
import { SwaggerUiComponent } from "./swagger-doc/swagger-ui.component";
//import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
  {
    path: PageRoutes.swagger,
    component: SwaggerUiComponent
  },
  {
    path: PageRoutes.login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: "",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
  },
  {
    path: "",
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: PageRoutes.signUp,
    loadChildren: () => import("./pages/signup/signup.module").then(m => m.SignUpPageModule),
  },
  {
    path: PageRoutes.development,
    loadChildren: () => import("./pages/development/development.module").then(m => m.DevelopmentPageModule),
  },
  {
    path: PageRoutes.countdown,
    loadChildren: () => import("./pages/countdown/countdown.module").then(m => m.CountdownPageModule),
  },
  {
    path: PageRoutes.scienceInsights,
    loadChildren: () => import("./pages/scienceInsights/scienceInsights.module").then(m => m.ScienceInsightsPageModule),
  },
  {
    path: PageRoutes.news,
    loadChildren: () => import("./pages/news/news.module").then(m => m.NewsPageModule),
  },
  {
    path: PageRoutes.ecg,
    loadChildren: () => import("./pages/ecg/ecg.module").then(m => m.EcgPageModule),
  },
  {
    path: PageRoutes.cardio3d,
    loadChildren: () => import("./pages/cardio3D/cardio3D.module").then(m => m.Cardio3DPageModule),
  },
  {
    path: PageRoutes.interactive,
    loadChildren: () => import("./pages/interactive/interactive.module").then(m => m.InteractivePageModule),
  },
  {
    path: PageRoutes.realCases,
    loadChildren: () => import("./pages/realCases/realCases.module").then(m => m.RealCasesPageModule),
  },
  {
    path: PageRoutes.privacypolicy,
    loadChildren: () => import("./pages/privacypolicy/privacypolicy.module").then(m => m.PrivacyPolicyModule),
  },
  /*
  {
    path: PageRoutes.downloadsoftware,
    loadChildren: () => import("./pages/downloadsoftware/downloadsoftware.module").then(m => m.DownloadSoftwareModule),
  },
  */
  {
    path: PageRoutes.legalInformation,
    loadChildren: () => import("./pages/legalInformation/legalInformation.module").then(m => m.LegalInformationModule),
  },
  {
    path: PageRoutes.resetPassword,
    loadChildren: () => import("./pages/resetPassword/resetPassword.module").then(m => m.resetPasswordPageModule),
  },
  {
    path: PageRoutes.restorePassword,
    loadChildren: () => import("./pages/restorePassword/restorePassword.module").then(m => m.restorePasswordPageModule),
  },
  {
    path: PageRoutes.collaborators,
    loadChildren: () => import("./pages/collaborators/collaborators.module").then(m => m.collaboratorsModule),
  },
  {
    path: PageRoutes.profile,
    loadChildren: () => import("./pages/profile/profile.module").then(m => m.profilePageModule),
  },
  {
    path: PageRoutes.team,
    loadChildren: () => import("./pages/team/team.module").then(m => m.teamPageModule),
  },
  {
    path: PageRoutes.cartoCases,
    loadChildren: () => import("./pages/cartoCases/cartoCases.module").then(m => m.CartoCasesPageModule),
  },
  {
    path: PageRoutes.vrcardio,
    loadChildren: () => import("./pages/vrcardio/vrcardio.module").then(m => m.vrcardioPageModule),
  },
  {
    path: PageRoutes.webcontent,
    loadChildren: () => import("./pages/webcontent/webcontent.module").then(m => m.webcontentPageModule),
  },
  {
    path: PageRoutes.webstats,
    loadChildren: () => import("./pages/webstats/webstats.module").then(m => m.webstatsPageModule),
  },
  {
    path: PageRoutes.users,
    loadChildren: () => import("./pages/users/users.module").then(m => m.usersPageModule),
  },
  {
    path: PageRoutes.dicom,
    loadChildren: () => import("./pages/dicom/dicom.module").then(m => m.DicomPageModule),
  },
  {
    path: PageRoutes.updatesubscription,
    loadChildren: () => import("./pages/updatesubscription/updatesubscription.module").then(m => m.UpdatesubscriptionPageModule),
  },
  {
    path: PageRoutes.quiz,
    loadChildren: () => import("./pages/quiz/quiz.module").then(m => m.QuizPageModule),
  },
  {
    path: PageRoutes.smsverification,
    loadChildren: () => import("./pages/smsVerification/smsVerification.module").then(m => m.smsVerificationPageModule),
  },
  {
    path: "**",
    redirectTo: PageRoutes.login
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top", useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
