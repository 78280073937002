import { createAction, props } from "@ngrx/store";
import { ScienceInsights } from "src/app/services/api";
import { ScienceInsightsControllerGetManyScienceInsightsRequestParams } from "src/app/services/api/api/scienceInsights.serviceInterface";

export const loadManyScienceInsights = createAction("[ScienceInsights] Load Many Science Insights", 
props<ScienceInsightsControllerGetManyScienceInsightsRequestParams>());
export const loadManyScienceInsightsSuccess = createAction("[ScienceInsights] Load Many Science Insights Success", 
props<{ manyScienceInsights: any, filtered: boolean }>());
export const loadManyScienceInsightsFailure = createAction("[ScienceInsights] Load Many Science Insights Failure");

export const loadScienceInsights = createAction("[ScienceInsights] Load Science Insights", props<{ scienceInsightsId: string }>());
export const loadScienceInsightsSuccess = createAction("[ScienceInsights] Load Science Insights Success", 
props<{ scienceInsights: ScienceInsights }>());
export const loadScienceInsightsFailure = createAction("[ScienceInsights] Load Science Insights Failure");

export const updateScienceInsights = createAction("[ScienceInsights] ScienceInsights Update", props<{ scienceInsights: any }>());
export const updateScienceInsightsSuccess = createAction("[ScienceInsights] Update ScienceInsights Success", props<{ scienceInsights: any }>());
export const updateScienceInsightsFailure = createAction("[ScienceInsights] Update ScienceInsights Failure", props<{ reason: string }>());

export const createScienceInsights = createAction("[ScienceInsights] ScienceInsights Create", props<{ scienceInsights: any }>());
export const createScienceInsightsSuccess = createAction("[ScienceInsights] Create ScienceInsights Success", props<{ scienceInsights: any }>());
export const createScienceInsightsFailure = createAction("[ScienceInsights] Create ScienceInsights Failure", props<{ reason: string }>());

export const deleteScienceInsights = createAction("[ScienceInsights] ScienceInsights Delete", props<{ scienceInsights: any }>());
export const deleteScienceInsightsSuccess = createAction("[ScienceInsights] Delete ScienceInsights Success", props<{ scienceInsightsId: any }>());
export const deleteScienceInsightsFailure = createAction("[ScienceInsights] Delete ScienceInsights Failure", props<{ reason: string }>());

export const getScienceInsights = createAction("[ScienceInsights] ScienceInsights get", props<{ scienceInsights: any }>());
export const getScienceInsightsSuccess = createAction("[ScienceInsights] get ScienceInsights Success", props<{ scienceInsights: any }>());
export const getScienceInsightsFailure = createAction("[ScienceInsights] get ScienceInsights Failure", props<{ reason: string }>());


