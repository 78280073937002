import { ScienceInsightsCategory } from "src/app/services/api/model/scienceInsightsCategory";

export const persistedKeys = ["total","manyScienceInsightsCategories"];

export interface ManyScienceInsightsCategories {
  [title: string]: ScienceInsightsCategory;
}

export interface ScienceInsightsCategoryState {
  manyScienceInsightsCategories: ManyScienceInsightsCategories;
  loading: boolean;
  total: number;
}

export const initialState: ScienceInsightsCategoryState = {
  manyScienceInsightsCategories: {},
  loading: false,
  total: 0,
};
