import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as RealCasesCategoryActions from "./actions";
import { RealCasesCategory } from "src/app/services/api/model/realCasesCategory";

export const realCasesCategoryReducer = createReducer(
  initialState,

  on(RealCasesCategoryActions.loadManyRealCasesCategories, RealCasesCategoryActions.loadRealCasesCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(RealCasesCategoryActions.loadManyRealCasesCategoriesFailure, RealCasesCategoryActions.loadRealCasesCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(RealCasesCategoryActions.loadManyRealCasesCategoriesSuccess, (state, { manyRealCasesCategories }) => {
    const newRealCasesCategories: { [key: string]: RealCasesCategory } = {};
    manyRealCasesCategories.forEach((realCasesCategory: RealCasesCategory) => {
      newRealCasesCategories[realCasesCategory.title!] = realCasesCategory;
    });
    return {
      ...state,
      manyRealCasesCategories: {
        ...newRealCasesCategories,
      },
      totalManyRealCasesCategories: manyRealCasesCategories.length,
      loading: false,
    };
  }),

  on(RealCasesCategoryActions.loadRealCasesCategorySuccess, (state, { realCasesCategory }) => {
    return {
      ...state,
      manyRealCasesCategories: {
        ...state.manyRealCasesCategories,
        [realCasesCategory.title!]: realCasesCategory,
      },
      loading: false,
    };
  })
);
