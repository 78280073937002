import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as DevelopmentCategoryActions from "./actions";
import { DevelopmentCategory } from "src/app/services/api/model/developmentCategory";

export const developmentCategoryReducer = createReducer(
  initialState,

  on(DevelopmentCategoryActions.loadManyDevelopmentCategories, DevelopmentCategoryActions.loadDevelopmentCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(DevelopmentCategoryActions.loadManyDevelopmentCategoriesFailure, DevelopmentCategoryActions.loadDevelopmentCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(DevelopmentCategoryActions.loadManyDevelopmentCategoriesSuccess, (state, { manyDevelopmentCategories }) => {
    const newDevelopmentCategories: { [key: string]: DevelopmentCategory } = {};
    manyDevelopmentCategories.forEach((developmentCategory: DevelopmentCategory) => {
      newDevelopmentCategories[developmentCategory.title!] = developmentCategory;
    });
    return {
      ...state,
      manyDevelopmentCategories: {
        ...newDevelopmentCategories,
      },
      totalManyDevelopmentCategories: manyDevelopmentCategories.length,
      loading: false,
    };
  }),

  on(DevelopmentCategoryActions.loadDevelopmentCategorySuccess, (state, { developmentCategory }) => {
    return {
      ...state,
      manyDevelopmentCategories: {
        ...state.manyDevelopmentCategories,
        [developmentCategory.title!]: developmentCategory,
      },
      loading: false,
    };
  })
);
