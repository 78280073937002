/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { from, Observable, of }                                        from 'rxjs';

import { ManyNews } from '../model/models';
import { News } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    NewsServiceInterface,
    NewsControllerCreateNewsRequestParams,
    NewsControllerDeleteNewsRequestParams,
    NewsControllerGetManyNewsRequestParams,
    NewsControllerGetNewsRequestParams,
    NewsControllerUpdateOneRequestParams
} from './news.serviceInterface';
import { Md5 } from 'ts-md5';
import { child, endAt, equalTo, get, getDatabase, limitToFirst, onValue, orderByChild, orderByKey, push, query, ref, remove, set, startAt, update } from 'firebase/database';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class NewsService implements NewsServiceInterface {

    //protected basePath = 'https://vrcardioexplorer-default-rtdb.firebaseio.com';
    protected basePath = environment.api;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        this.configuration.basePath = this.basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async newsControllerCreateNews(requestParameters: NewsControllerCreateNewsRequestParams): Promise<Observable<News>> {
        let url = environment.createnew;
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(requestParameters) 
        };
    
        let result: any = await fetch(url, requestOptions);
        return result;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async newsControllerDeleteNews(requestParameters: NewsControllerDeleteNewsRequestParams): Promise<any> {
        let url = environment.deletenew; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(requestParameters) 
        };
    
        let result: any = await fetch(url, requestOptions);
        return await result.json();
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async newsControllerGetManyNews(requestParameters: NewsControllerGetManyNewsRequestParams): Promise<Observable<any>> {
        let url = environment.getmanynews;
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestParameters)
        };
    
        try {
            let response = await fetch(url, requestOptions);
            let result = await response.json();
            let resultNewsCopy = {...result.news[0]}
            Object.keys(result.news[0]).forEach((element:any)=>{
                if(result.news[0][element].id == undefined){
                    delete resultNewsCopy[element];
                }
            })
            result = {
                ...result,
                news: [resultNewsCopy]
            }            

            return result;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async newsControllerGetNews(requestParameters: NewsControllerGetNewsRequestParams): Promise<any> {
        let url = environment.getnew; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(requestParameters)
        };
    
        try {
            let response = await fetch(url, requestOptions);
            let result = await response.json();
            return result;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async newsControllerUpdateOne(requestParameters: NewsControllerUpdateOneRequestParams): Promise<any> {
        let url = environment.updatenew; 
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestParameters)
        };
    
        try {
            let response = await fetch(url, requestOptions);
            let result = await response.json();
            return result;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

}
