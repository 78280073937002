/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterBody { 
    email: string;
    password: string;
    username: string;
    firstname?: string;
    lastname?: string;
    language?: string;
    country?: string;
    mobile?: string;
    profile?: string;
    subscribe?: boolean;
    device_uuid?: string;
    device_name?: string;
}

