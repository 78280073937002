<div class="videoIntro" id="videoIntro">
    <video
        playsinline 
        class="videoIntro__video" 
        id="videoElement" 
        src="assets/VR_Cardio_Final.mp4"
        autoplay="true"
        muted="muted">
    </video>
    
    <div class="videoIntro__button" id="videoIntro__button" (click)="skipVideo()">
        <span class="videoIntro__button__text" [innerText]="'HOME.SKIP' | translate"></span>
        <span class="videoIntro__button__icon"> <i class="fa fa-fast-forward" aria-hidden="true"></i> </span>
    </div>
</div>