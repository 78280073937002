<div class="header">
    <div class = "header__top">
    </div>
    <div class="header__header" id="headerFlagSelection">
        <button class="header__dropdown__item" (click)="toggleDropdown()">
            <img class="header__dropdown__item__flag" id="enCurrentFlagImage" src="assets/en.webp" alt="">
            <img class="header__dropdown__item__flag" id="esCurrentFlagImage" src="assets/es.webp" alt="">
            <img class="header__dropdown__item__flag" id="arCurrentFlagImage" src="assets/ar.webp" alt="">
        </button>
        <div class="header__dropdown__content" id="languageDropdown">
            <a href="#" id="header__dropdown__content__flag__en" class="header__dropdown__content__flag" (click)="changeLanguage('en')"><img src="assets/en.webp" alt="" class="header__dropdown__content__flag__img"></a>
            <a href="#" id="header__dropdown__content__flag__es" class="header__dropdown__content__flag" (click)="changeLanguage('es')"><img src="assets/es.webp" alt="" class="header__dropdown__content__flag__img"></a>
            <a href="#" id="header__dropdown__content__flag__ar" class="header__dropdown__content__flag" (click)="changeLanguage('ar')"><img src="assets/ar.webp" alt="" class="header__dropdown__content__flag__img"></a>
        </div>
    </div>

    <div class = "header__middle">
        <div class = "header__middle__content" >
            <img class="header__middle__content__img__left" src="assets/heartTransparent.png" alt="" (click) = "goToPage('VRCARDIO', userName)">
            <span id="header__middle__content__text" class="header__middle__content__text" [innerText]="'HEADER.VRCARDIOEXPLORE' | translate" (click) = "goToPage('VRCARDIO',userName)"></span>
            <img class="header__middle__content__img__right" src="assets/CarciacAnatomyAtlas.png" alt="" (click) = "navigateTo('ATLAS')">
        </div>

        <div class="header__middle__media">
            <div class = "header__middle__media__login" (click) = "goToPage('PROFILE', userName)">
                <span class="header__middle__media__login__icon" (click) = "goToPage('PROFILE', userName)"><i class="fa fa-user" aria-hidden="true" (click) = "goToPage('PROFILE',userName)"></i></span>
            </div>

            <div *ngIf="isUserLoggedIn" class = "header__middle__media__signout" (click) = "signOut()">
                <span class="header__middle__media__signout__icon" (click) = "signOut()"><i class="fa fa-sign-out" aria-hidden="true"></i></span>
            </div>

            <div class="header__middle__media__dropdown">
                <button class="header__middle__media__dropdown__button">⁝</button>
                <div class="header__middle__media__dropdown__content">
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.VRCARDIO' | translate" (click) = "goToPage('VRCARDIO', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.NEWS' | translate" (click) = "goToPage('NEWS', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.DEVELOPMENT' | translate" (click) = "goToPage('DEVELOPMENT', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.QUIZ' | translate" (click) = "goToPage('QUIZ', userName)"></span>
                    <!--<span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.LAUNCH' | translate" (click) = "goToPage('COUNTDOWN', userName)"></span>-->
                    <!--<span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.DOWNLOADSOFTWARE' | translate" (click) = "goToPage('DOWNLOADSOFTWARE', userName)"></span>-->
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.SCIENCEINSIGHTS' | translate" (click) = "goToPage('SCIENCEINSIGHTS', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.CLINICALCASES' | translate" (click) = "goToPage('CLINICALCASES', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.CARTOCASES' | translate" (click) = "goToPage('CARTOCASES', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.DICOM' | translate" (click) = "goToPage('DICOM', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.INTERACTIVE' | translate" (click) = "goToPage('INTERACTIVE', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.CARDIO3D' | translate" (click) = "goToPage('CARDIO3D', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.ECG' | translate" (click) = "goToPage('ECG', userName)"></span>
                    <span class="header__middle__media__dropdown__content__label" [innerText]="'HEADER.ATLAS' | translate" (click) = "goToPage('ATLAS', userName)"></span>
                </div>
            </div>
        </div>
    </div>

    <div class = "header__bottom">
        <div class = "header__bottom__left">
            <div class="header__bottom__left__VRCARDIO">
                <div class="header__bottom__left__VRCARDIO__button">
                    <span *ngIf="!isVRCardioPage" id="header__tabs__text__VRCardio" class="header__bottom__left__VRCARDIO__button__label" [innerText]="'HEADER.VRCARDIO' | translate" (click) = "goToPage('VRCARDIO', userName)" ></span>
                    <span *ngIf="isVRCardioPage" id="header__tabs__text__VRCardio" class="header__bottom__left__VRCARDIO__button__underline__label" [innerText]="'HEADER.VRCARDIO' | translate" (click) = "goToPage('VRCARDIO', userName)" ></span>    
                </div>
                
                <div class="header__bottom__left__VRCARDIO__content">
                    <span *ngIf="!isNewsPage" id="header__tabs__text__news"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.NEWS' | translate" (click) = "goToPage('NEWS', userName)"></span>
                    <span *ngIf="isNewsPage"  id="header__tabs__text__news"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.NEWS' | translate" (click) = "goToPage('NEWS', userName)"></span>
                    <span *ngIf="!isScienceInsightsPage" id="header__tabs__text__scienceInsights"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.SCIENCEINSIGHTS' | translate" (click) = "goToPage('SCIENCEINSIGHTS', userName)"></span>
                    <span *ngIf="isScienceInsightsPage"  id="header__tabs__text__scienceInsights"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.SCIENCEINSIGHTS' | translate" (click) = "goToPage('SCIENCEINSIGHTS', userName)"></span>
                    <span *ngIf="!isDevelopmentPage" id="header__tabs__text__development"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.DEVELOPMENT' | translate" (click) = "goToPage('DEVELOPMENT', userName)"></span>
                    <span *ngIf="isDevelopmentPage" id="header__tabs__text__development"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.DEVELOPMENT' | translate" (click) = "goToPage('DEVELOPMENT', userName)"></span>
                    <span *ngIf="!isQuizPage" id="header__tabs__text__quiz"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.QUIZ' | translate" (click) = "goToPage('QUIZ', userName)"></span>
                    <span *ngIf="isQuizPage" id="header__tabs__text__quiz"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.QUIZ' | translate" (click) = "goToPage('QUIZ', userName)"></span>
                    <!--<span *ngIf="!isDownloadSoftwarePage" id="header__tabs__text__downloadSoftware"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.DOWNLOADSOFTWARE' | translate" (click) = "goToPage('DOWNLOADSOFTWARE', userName)"></span>
                    <span *ngIf="isDownloadSoftwarePage" id="header__tabs__text__downloadSoftware"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.DOWNLOADSOFTWARE' | translate" (click) = "goToPage('DOWNLOADSOFTWARE', userName)"></span>-->
                    <!--<span *ngIf="!isCountdownPage" id="header__tabs__text__countdown"  class="header__bottom__left__VRCARDIO__content__label" [innerText]="'HEADER.LAUNCH' | translate" (click) = "goToPage('COUNTDOWN', userName)"></span>
                    <span *ngIf="isCountdownPage" id="header__tabs__text__countdown"  class="header__bottom__left__VRCARDIO__content__underline__label" [innerText]="'HEADER.LAUNCH' | translate" (click) = "goToPage('COUNTDOWN', userName)"></span>-->
                </div>
            </div>

            <span *ngIf="!isInteractivePage" id="header__tabs__text__interactive"  class="header__bottom__left__label" [innerText]="'HEADER.INTERACTIVE' | translate" (click) = "goToPage('INTERACTIVE', userName)"></span>
            <span *ngIf="isInteractivePage" id="header__tabs__text__interactive"  class="header__bottom__left__underline__label" [innerText]="'HEADER.INTERACTIVE' | translate" (click) = "goToPage('INTERACTIVE', userName)"></span>
            
            <div class="header__bottom__left__REALCASES">
                <div class="header__bottom__left__REALCASES__button">
                    <span *ngIf="!isRealCases" id="header__tabs__text__RealCases" class="header__bottom__left__REALCASES__button__label" [innerText]="'HEADER.OBSERVATIONS' | translate"></span>
                    <span *ngIf="isRealCases" id="header__tabs__text__RealCases" class="header__bottom__left__REALCASES__button__underline__label" [innerText]="'HEADER.OBSERVATIONS' | translate"></span>    
                </div>
                
                <div class="header__bottom__left__REALCASES__content">
                    <span *ngIf="!isClinicalCasesPage" id="header__tabs__text__clinicalCases"  class="header__bottom__left__REALCASES__content__label" [innerText]="'HEADER.CLINICALCASES' | translate" (click) = "goToPage('CLINICALCASES', userName)"></span>
                    <span *ngIf="isClinicalCasesPage"  id="header__tabs__text__clinicalCases"  class="header__bottom__left__REALCASES__content__underline__label" [innerText]="'HEADER.CLINICALCASES' | translate" (click) = "goToPage('CLINICALCASES', userName)"></span>
                    <span *ngIf="!isCartoCasesPage" id="header__tabs__text__cartoCases"  class="header__bottom__left__REALCASES__content__label" [innerText]="'HEADER.CARTOCASES' | translate" (click) = "goToPage('CARTOCASES', userName)"></span>
                    <span *ngIf="isCartoCasesPage"  id="header__tabs__text__cartoCases"  class="header__bottom__left__REALCASES__content__underline__label" [innerText]="'HEADER.CARTOCASES' | translate" (click) = "goToPage('CARTOCASES', userName)"></span>
                    <span *ngIf="!isDicomPage" id="header__tabs__text__dicom"  class="header__bottom__left__REALCASES__content__label" [innerText]="'HEADER.DICOM' | translate" (click) = "goToPage('DICOM', userName)"></span>
                    <span *ngIf="isDicomPage"  id="header__tabs__text__dicom"  class="header__bottom__left__REALCASES__content__underline__label" [innerText]="'HEADER.DICOM' | translate" (click) = "goToPage('DICOM', userName)"></span>
                </div>
            </div>
            
            <span *ngIf="!isCardio3DPage" id="header__tabs__text__cardio3D"  class="header__bottom__left__label" [innerText]="'HEADER.CARDIO3D' | translate" (click) = "goToPage('CARDIO3D', userName)"></span>
            <span *ngIf="isCardio3DPage" id="header__tabs__text__cardio3D"  class="header__bottom__left__underline__label" [innerText]="'HEADER.CARDIO3D' | translate" (click) = "goToPage('CARDIO3D', userName)"></span>
            <span *ngIf="!isECGPage" id="header__tabs__text__ecg"  class="header__bottom__left__label" [innerText]="'HEADER.ECG' | translate" (click) = "goToPage('ECG', userName)"></span>
            <span *ngIf="isECGPage" id="header__tabs__text__ecg"  class="header__bottom__left__underline__label" [innerText]="'HEADER.ECG' | translate" (click) = "goToPage('ECG', userName)"></span>
            <div class="header__bottom__left__AOE">
                <div class="header__bottom__left__AOE__button">
                    <span id="header__tabs__text__AOE"  class="header__bottom__left__AOE__button__label" [innerText]="'HEADER.AOE' | translate" (click) = "navigateTo('AOE')"></span>
                </div>
                
                <div class="header__bottom__left__AOE__content">
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.ATLAS' | translate" (click) = "navigateTo('ATLAS')"></span>
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.MINIATLAS' | translate" (click) = "navigateTo('MINIATLAS')"></span>
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.VIDEOS' | translate" (click) = "navigateTo('VIDEOS')"></span>
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.TUTORIALS' | translate" (click) = "navigateTo('TUTORIALS')"></span>
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.CLINICALCASES' | translate" (click) = "navigateTo('CLINICALCASES')"></span>
                    <span class="header__bottom__left__AOE__content__label" [innerText]="'HEADER.REFERENCES' | translate" (click) = "navigateTo('REFERENCES')"></span>
                </div>
            </div>
        </div>
        <div  class = "header__bottom__right">
            <span class="header__bottom__right__icon" (click) = "goToPage('PROFILE', userName)"><i class="fa fa-user" aria-hidden="true"></i></span>
            <span *ngIf="isUserLoggedIn" id="header__bottom__right__name" class="header__bottom__right__name" (click) = "goToPage('PROFILE', userName)" [innerText]="userName"></span>
            <span *ngIf="isUserLoggedIn" class="header__bottom__right__icon" (click) = "signOut()"><i class="fa fa-sign-out" aria-hidden="true"></i></span>
        </div>
    </div>
</div>